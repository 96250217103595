
import React, { useState } from "react";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-awesome-modal';
import { PhoneInput } from "react-contact-number-input";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { AdminApis } from "../../apis/adminApi";
import { FaTrash, FaEdit } from "react-icons/fa";
import { AiOutlineWarning } from "react-icons/ai";
import EmojiPicker from 'emoji-picker-react';
import { useParams } from 'react-router-dom';
import { MutatingDots } from 'react-loader-spinner'
import { ThreeDots } from 'react-loader-spinner'
import { TailSpin } from 'react-loader-spinner'
import { Oval } from 'react-loader-spinner'
import { CategoryApis } from "../../apis/CategoryApis";
import { FaArrowLeft } from "react-icons/fa";

// components



export default function CardEditProduct() {
  const navigate = useNavigate();

  const locations = useLocation();
  const { current_page } = locations.state || {};
  const { search } = locations.state || {};

  // console.log('Current Page:', current_page); // Now you can use this value in the component
  // console.log('search:', search); // Now you can use this value in the component

  let [visible, setVisible] = React.useState(false);
  const [addLink, setAddLink] = React.useState([]);

  const [addContact, setAddContact] = React.useState([]);

  const [productLink, setProductLink] = React.useState([]);
  const params = useParams();

  const [productName, setProductName] = useState('');
  const [productId, setProductId] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [noOfItems, setNoOfItems] = useState('');
  const [category, setCategory] = useState('');
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState('');
  const [count, setCount] = useState('');

  const [imgId1, setImgId1] = useState('');
  const [imgId2, setImgId2] = useState('');
  const [imgId3, setImgId3] = useState('');

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };


  function toggleModal() {
    setVisible(!visible)
  }

  // setLinkName(addLink.split(" "))
  // console?.log((addLink.split(" "))[1])




  const [img1, setImg1] = React.useState('No selected file');
  const [img12, setImg12] = React.useState('empty');
  function uploadImg1(e) {
    let size = (e.target.files[0].size / 1048576.0)
    setImg12(URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      if (size > 4) {
        if (e.target.name == 'uploadImg1') {
          e.target.value = ''
        }
      }
      if (size <= 4) {
        if (e.target.name == 'uploadImg1') {
          setImg1(e.target.files[0]);
        }
      }
    }
  };


  const [img2, setImg2] = React.useState('No selected file');
  const [img22, setImg22] = React.useState('empty');
  function uploadImg2(e) {
    let size = (e.target.files[0].size / 1048576.0)
    setImg22(URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      if (size > 2) {
        if (e.target.name == 'uploadImg2') {
          e.target.value = ''
        }
      }
      if (size <= 2) {
        if (e.target.name == 'uploadImg2') {
          setImg2(e.target.files[0]);
        }
      }
    }
  };

  const [img3, setImg3] = React.useState('No selected file');
  const [img32, setImg32] = React.useState('empty');
  function uploadImg3(e) {
    let size = (e.target.files[0].size / 1048576.0)
    setImg32(URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      if (size > 2) {
        if (e.target.name == 'uploadImg3') {
          e.target.value = ''
        }
      }
      if (size <= 2) {
        if (e.target.name == 'uploadImg3') {
          setImg3(e.target.files[0]);
        }
      }
    }
  };

  const [loader, setLoader] = React.useState(false);
  let [data, setdata] = React.useState(0);
  let [checkLink, setCheckLink] = React.useState('');


  React.useEffect(() => {
    AdminApis.getSingleProduct(params?.id).then(
      (response) => {
        if (response?.data) {
          // console.log(response?.data?.data?.product);
          setProductName(response?.data?.data?.product?.product_name);
          setProductDescription(response?.data?.data?.product?.product_description);
          setPrice(response?.data?.data?.product?.product_price)
          setNoOfItems(response?.data?.data?.product?.no_of_items)
          setCount(response?.data?.data?.product?.link_name)
          setAddLink(response?.data?.data?.product?.link_name)
          setCategory(response?.data?.data?.product?.category)
          setLocation(response?.data?.data?.product?.location)
          setPhoneNumber(response?.data?.data?.product?.phone_number)
          setPhoneNumber(response?.data?.data?.product?.phone_number)
          setImg12(response?.data?.data?.product?.product_image_1)
          setImg1(response?.data?.data?.product?.product_image_1)
          setImg22(response?.data?.data?.product?.product_image_2)
          setImg2(response?.data?.data?.product?.product_image_2)
          setImg32(response?.data?.data?.product?.product_image_3)
          setImg3(response?.data?.data?.product?.product_image_3)
          setProductId(response?.data?.data?.product?.id)
          setImgId1(response?.data?.data?.product?.product_image_id_1)
          setImgId2(response?.data?.data?.product?.product_image_id_2)
          setImgId3(response?.data?.data?.product?.product_image_id_3)

        }
      }
    );

  }, []);

  React.useEffect(() => {
    CategoryApis.getCategory('').then(
      (response) => {
        if (response?.data) {
          setTotal(response?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error
      console.log(error.response.data);
    })

  }, []);


  const [total, setTotal] = React.useState('');


  const createProduct = React.useCallback(
    (e) => {
      e.preventDefault();
      setLoader(true);
      const formData = new FormData()
      formData.append('link_name', count)
      formData.append('link_id', '--')
      formData.append('product_name', productName)
      formData.append('product_description', productDescription)
      formData.append('phone_number', phoneNumber)
      formData.append('no_of_items', noOfItems)
      formData.append('id', productId)
      formData.append('category', category)
      formData.append('location', location)
      formData.append('product_price', price)
      formData.append('product_image_1', img1)
      formData.append('product_image_2', img2)
      formData.append('product_image_3', img3)
      formData.append('product_image_id_1', imgId1)
      formData.append('product_image_id_2', imgId2)
      formData.append('product_image_id_3', imgId3)

      AdminApis.updateProduct(formData).then(
        (response) => {
          if (response?.data) {
            // console.log(response?.data)
            // toggleModal()
            setLoader(false);
            toast.success("Product Updated Successfully");
            if (search !== '' ) {
              navigate(`/admin/products?name=${search}`);
      
             }  else if (search !== '' && current_page > 0) {
              navigate(`/admin/products?name=${search}?page=${current_page}`);

      
              
             }
          //    else if (value2 && search === '') {
          // navigate(`/admin/products?page=${value2}`);
              
          //    } 
          else if (current_page > 0 && search === '') {
            navigate(`/admin/products?page=${current_page}`);
    
            
           } else if (typeof current_page === 'object' && search === '') {
              navigate(`/admin/products?name=${search}`);
      
              
             } 
              else {
              navigate(`/admin/products`);
      
      
             }
            // navigate(`/admin/products?page=${current_page}`); // Use the currentPage variable here
          } else {
            // toggleModal()
            toast.error('link name already in use');

          }

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        // console.log(error.response);
        toast.error(error.response.data.message);
      })
    },
    [addLink, productName, productDescription, count, noOfItems, price, img1, img2, img3, productId, imgId1, imgId2, imgId3, phoneNumber, location, category]
  );


  return (
    <>

      <form onSubmit={createProduct} className="pb-32 sm:px-5">

        <div className="flex justify-between items-center">

          {/* Back button */}
          <button type="button" onClick={handleBackClick} className="flex items-center text-gray-600 mb-4">
        <FaArrowLeft className="mr-2" />
        <span>Back</span>
      </button>

        <div className="flex justify-end gap-2 mt-5">
          {/* <button
            type="button"
            onClick={toggleModal}
            className=" text-gray-900 bg-[#8ed2ff] hover:bg-[#167bbe] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center "
          >
            Add Market Link
          </button> */}
          {
            loader ?
              <div className='flex justify-center '>
                <Oval
                  height={40}
                  width={40}
                  color="#0071BC"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loader}
                  ariaLabel='oval-loading'
                  secondaryColor="#96cff6"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
              :
              ''
          }

          <button
            type="submit"
            className=" text-white bg-[#0071BC] hover:bg-[#103f5e] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center "
          >
            Update Product
          </button>
        </div>

        </div>
        <div className={"lg:grid lg:grid-cols-2 gap-2 mt-20 " + (loader ? 'shadow animate-pulse ' : '')}>
          <div className="mb-10">
            <div className=" lg:w-4/5">
              <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Product Name</label>
              <input type="text" defaultValue={productName} onChange={(e) => setProductName(e?.target?.value)} id="first_name" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Title of business here" />

              <label for="first_name" class="block mb-2 mt-4 text-sm  text-gray-900 dark:text-gray-600 ">Product Description</label>
              <textarea id="message" defaultValue={productDescription} onChange={(e) => setProductDescription(e?.target?.value)} rows={3} className="block bg-[#F4FBFF] p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-gray-500 focus:border-gray-500" placeholder="Bio" ></textarea>

              <div className="grid md:grid-cols-2 gap-2">
                <div>
                  <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Category</label>
                  <select id="programs_type" value={category} onChange={e => setCategory(e.target.value)} name="programs_type" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 cursor-pointer">

                    {total?.data?.map(
                      (datas, index) => (
                       <option key={index} value={datas?.id} selected={category === datas?.id}>
                          {datas?.cat_name}
                        </option>
                      )
                    )
                    }
                  </select>

                </div>


                <div>
                  <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Sales Price</label>
                  <input type="number" defaultValue={price} onChange={(e) => setPrice(e?.target?.value)} id="first_name" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Eg. 3500" />
                </div>
              </div>

              <div className="grid md:grid-cols-2 gap-2">
                <div>
                  <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Sizes</label>
                  <input type="text" defaultValue={count} onChange={(e) => setCount(e?.target?.value)} id="first_name" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Lg,Md,Sm" />
                </div>

                <div>
                  <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Number of Items </label>
                  <input type="number" defaultValue={noOfItems} onChange={(e) => setNoOfItems(e?.target?.value)} id="first_name" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="1,2,3..." />
                </div>
              </div>






            </div>

          </div>

          {/* second Div */}
          <div className="grid grid-cols-2 gap-2">
            <div className="">
              <label className="flex flex-col items-center justify-center w-full  rounded-[5px] cursor-pointer ">
                <div className="flex flex-col items-center justify-center  ">
                  {img12 == 'no image' ? <img src="/images/img1.png" style={{ minHeight: '200px', maxHeight: "200px" }} /> : <img src={img12} style={{ minHeight: '200px', maxHeight: "200px" }} />}
                </div>
                <input id="dropzone-file" onChange={uploadImg1} accept="image/x-png,image/gif,image/jpeg" name='uploadImg1' type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
              </label>
            </div>

            <div className="">
              <label className="flex flex-col items-center justify-center w-full  rounded-[5px] cursor-pointer ">
                <div className="flex flex-col items-center justify-center ">
                  {img22 == 'no image' ? <img src={`/images/img2.png`} alt={img22} style={{ minHeight: '200px', maxHeight: "200px" }} /> : <img src={img22} style={{ minHeight: '200px', maxHeight: "200px" }} />}
                </div>
                <input id="dropzone-file" name='uploadImg2' accept="image/x-png,image/gif,image/jpeg" onChange={uploadImg2} type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
              </label>
            </div>

            <div className="">
              <label className="flex flex-col items-center justify-center w-full  rounded-[5px] cursor-pointer ">
                <div className="flex flex-col items-center justify-center ">
                  {img32 == 'no image' ? <img src="/images/img3.png" style={{ minHeight: '200px', maxHeight: "200px" }} /> : <img src={img32} style={{ minHeight: '200px', maxHeight: "200px" }} />}
                </div>
                <input id="dropzone-file" name='uploadImg3' accept="image/x-png,image/gif,image/jpeg" onChange={uploadImg3} type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
              </label>
            </div>


          </div>

        </div>


      </form>







      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}
