import React, { useEffect } from 'react'
import Navbar from '../Navbars/Navbar';
import UserSidebar from '../Sidebar/UserSidebar';
import OrderCardUserPurchaseList from '../Cards/OrderCardUserPurchaseList';

const OrderPurchaseLists = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>

    <Navbar />
    <div className="flex relative  bg-white">

    <div className="hidden md:flex md:w-1/5">
        <UserSidebar title="Purchase List" />
      </div>

      <div className="md:w-4/5 w-full md:px-10 ">
      <OrderCardUserPurchaseList />
      </div>
    
      
     
    </div>




  </>
  )
}

export default OrderPurchaseLists