
import React from 'react';
import { Route, RouteProps } from 'react-router';
import utc from 'dayjs/plugin/utc'

import dayjs, { Dayjs } from "dayjs";

export function validateEmail(email: string): boolean {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function covertDateTimeField(date: Date | string): string {
  if (dayjs(date).isValid()) {
    return dayjs(date).format("DD - MMM h:mm A");
  }
  return date as string;
}

export function getDateDifference(date:Date){
 return dayjs(date).diff(dayjs(), 'day');
}

export function formatDateTime(date: Date | string, format?: string) {
  if (date) {
    return dayjs(date).format(format || "MMM DD, YYYY hh:mm:ss A");
  }

  return date;
}

export function convertUTCDateToLocalDate(date: Date | string) {
  try {
    if (date) {
      var _date = new Date(date);
      var newDate = new Date(date);

      newDate.setMinutes(_date.getMinutes() - _date.getTimezoneOffset());
      return dayjs(newDate).format("DD - MMM hh:mm:ss a");
    }
    return date;
  } catch (err) {
    return [] as any;
  }
}
export function convertUTCDateToLocalDateChart(date: Date | string) {
  try {
    if (date) {
      var _date = new Date(date);
      var newDate = new Date(date);
      newDate.setMinutes(_date.getMinutes() - _date.getTimezoneOffset());

      return dayjs(newDate).format("DD - MMM hh:mm:ss a");
    }
    return date;
  } catch (err) {
    return [] as any;
  }
}


export function convertPeriodToFromAndTo(period: string) {
  try {
    if (period) {
      dayjs.extend(utc)

      var utcNow = dayjs.utc().format();
      var currHour = dayjs.utc().get('hour');
      var currDay = dayjs().get('day');

      if (period == 'LastHour') {
        var lastHour = dayjs().utc().hour(dayjs.utc().hour() - 1).format();
        var currHours = dayjs().utc().hour(currHour).format();
        return (({ 'from': lastHour, 'to': currHours }))
      }
      else if (period == 'Today') {
        var st = new Date();
        st.setFullYear(dayjs().year(), dayjs().month(), dayjs().day())
        st.setHours(0); st.setMinutes(0); st.setSeconds(0);
        var startOfDay = dayjs(st).utc().format();
       
        return ({ 'from': startOfDay, 'to': utcNow })
      
      }
      else if (period == 'ThisWeek') {
        var weekfirstDay = dayjs().utc().day(dayjs().utc().day() - currDay).format();
        
        return ({ 'from': weekfirstDay, 'to': utcNow })

      }
      else if (period == 'Last7Days') {
        var lastSevenDays = dayjs().utc().day(dayjs().utc().day() - 7).format();
       
        return ({ 'from': lastSevenDays, 'to': utcNow })

      }
      else if (period == 'Last30Days') {
        var lastThirtyDays = dayjs().utc().day(dayjs().utc().day() - 30).format();
        
        return ({ 'from': lastThirtyDays, 'to': utcNow })
     
      }
      else if (period == 'ThisMonth') {
        var sm = new Date(dayjs().year(), dayjs().month(), 1, 0, 0, 0);
        var startOfMonth = dayjs(sm).utc().format();
       
        return ({ 'from': startOfMonth, 'to': utcNow })

      }
      else if (period == 'LastMonth') {
        var slm = new Date(dayjs().year(), (dayjs().month() - 1), 1, 0, 0, 0);
        var startOfLastMonth = dayjs(slm).utc().format();
       
        var locatTime = new Date(dayjs().year(), dayjs().month(), 1, 0, 0, 0);
        locatTime.setMinutes(locatTime.getMinutes() - 1)
        var elm = dayjs(locatTime).utc();
        var endOfLastMonth = elm.format();
       
        return ({ 'from': startOfLastMonth, 'to': endOfLastMonth })

      }
      else if (period == 'ThisYear') {
        var firstMonth = dayjs().month(0).format();
        var currYear = dayjs().get('year');
        var currYears = dayjs().year(currYear).format();

        return ({ 'from': firstMonth, 'to': currYears })
      }
    }
    return period;
  } catch (err) {
    return [] as any;
  }
}



export function convertBreakdownPeriodToFromAndTo(period: string,year:string) {
  try {
    if (period) {
      dayjs.extend(utc)

      var utcNow = dayjs.utc().format();
      var currHour = dayjs.utc().get('hour');
      var currDay = dayjs().get('day');

     
       if (period == 'LastSixMonth') {
        var slm = new Date(dayjs().year(), (dayjs().month() - 6), 1, 0, 0, 0);
        var startOfLastMonth = dayjs(slm).utc().format();
       
        var locatTime = new Date(dayjs().year(), dayjs().month(), 1, 0, 0, 0);
        locatTime.setMinutes(locatTime.getMinutes() - 1)
        var elm = dayjs(locatTime).utc();
        var endOfLastMonth = elm.format();
       
        return ({ 'from': startOfLastMonth, 'to': endOfLastMonth })

      }
      else if (period == 'FirstSixMonth') {
        var slm = new Date(dayjs().year(), (dayjs().month() + 6), 1, 0, 0, 0);
        var startOfLastMonth = dayjs(slm).utc().format();
       
        var locatTime = new Date(dayjs().year(), dayjs().month(), 1, 0, 0, 0);
        locatTime.setMinutes(locatTime.getMinutes() - 1)
        var elm = dayjs(locatTime).utc();
        var endOfLastMonth = elm.format();
       
        return ({ 'from': startOfLastMonth, 'to': endOfLastMonth })

      }
      else if (period == 'ThisYear') {
        var firstMonth = dayjs().month(0).format();
        var currYear = dayjs().get('year');
        var currYears = dayjs().year(currYear).format();

        return ({ 'from': firstMonth, 'to': currYears })
      }
    }
    return period;
  } catch (err) {
    return [] as any;
  }
}



export function convertUTCDateToLocalDateLineChart(date: Date | string, category: any) {
  try {
    if (date) {
      var _date = new Date(date);
      var newDate = new Date(date);
      newDate.setMinutes(_date.getMinutes() - _date.getTimezoneOffset());
      if (category == 'Today') {
        return dayjs(newDate).format("hh:mm a");
      } else if (category == 'LastHour') {
        return dayjs(newDate).format("hh:mm a");
      } else {
        return dayjs(newDate).format("DD - MMM\nhh:mm a");
      }
    }
    return date;
  } catch (err) {
    return [] as any;
  }
}

export function convertStringToJson<T>(strValue: string): T {
  try {
    return JSON.parse(strValue);
  } catch (err) {
    return [] as any;
  }
}

// interface AppRouteProps extends RouteProps {
//   component: any
//   props?: any;
// }

// export const AppRoute = (props: AppRouteProps) => (
//   <Route
//     {...props}
//     render={(renderProps) => {
//       return (
//         <props.component {...renderProps}{...props.props} />
//       )
//     }
//     } />
// );

export const validEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email) ? undefined : "invalid email";
}