import React from "react";
import { AdminApis } from "../../apis/adminApi";
import { FaTrash, FaEdit, FaWhatsapp } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
// import { url } from "inspector";
import { store } from "../../store/store";
import { Oval } from 'react-loader-spinner'
import configs from "../../configs";
import { Dispatch } from "redux";
import { login } from '../../reducer/loginSlice'
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { formatDateTime } from "../Reusable/functions";

// components

export default function CardDashboard() {

  const userLoginData = useSelector((state) => state.data.login.value);
  let [visible, setVisible] = React.useState(false);
  let [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);
  let [see, setSee] = React.useState(false);
  let [value, setvalue] = React.useState('');


  const [userLists, setUserList] = React.useState([]);
  const [purchaseList, setPurchaseList] = React.useState([]);
  let [stat, setStat] = React.useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [contact, setContact] = React.useState('');


  // console?.log(value)

  function toggleModal(value2, contact) {
    setvalue(value2)
    setContact(contact)
    setVisible(!visible)
  }


  React.useEffect(() => {
    if (!userLoginData?.id) {
      navigate('/sign-in');
    }
  }
  );


  React.useEffect(() => {
    AdminApis.getDashboardData().then(
      (response) => {
        if (response?.data) {
          setUserList(response?.data?.data?.purchase)
          setPurchaseList(response?.data?.data?.customers)
          // console?.log(response?.data?.data)
          setStat(response?.data?.data)
        } else {
          dispatch(login([]))
        }
      }
    ).catch(function (error) {

    })
  }, []);

  function toggleDelete(value2) {
    setvalue(value2)

    setToggleDeleteModal(!visible)
  }
  // console.log(contact)

  const [message, setMessage] = React.useState("");
  const [name, setName] = React.useState("");


  function isCopied() {
    toast.success("Copied to Clipboard");
  }

  const [loader, setLoader] = React.useState(true);
  function isCopied() {
    toast.success("Copied to Clipboard");
  }

  let [data, setdata] = React.useState([]);








  return (
    <>
      <div className="relative p-3 flex flex-col min-w-0 break-words bg-[#F8F8F8] w-full mb-6  rounded">


        <div className='grid lg:grid-cols-4 grid-cols-2 gap-6'>
          <div className=''>
            <div className='bg-white rounded-[10px] px-[15px] pt-[20px] pb-[24px] border-[1px] border-[#dcdada]'>
              <h5 className='text-[12px]'>Total Customers</h5>
              <div className='flex justify-between gap-4'>
                <h1 className='text-[24px] font-semibold'>{stat?.totalCustomers}</h1>
                <div className='flex space-x-1 mt-2 '>
                  <div className='mt-1'>
                    <svg width="85" height="41" viewBox="0 0 85 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_57_1136)">
                        <path d="M5 24.3152C7.01238 25.1501 12.3839 25.0894 17.7709 18.1671C24.5046 9.51427 30.774 11.7913 38.2043 21.5827C45.6347 31.3741 50.743 36.156 58.4056 22.7213C66.0681 9.2866 66.9969 4.73248 80 2" stroke="#0071DC" stroke-width="3" />
                      </g>
                      <defs>
                        <filter id="filter0_d_57_1136" x="0.425171" y="0.532104" width="83.8833" height="39.9683" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0 0.941176 0 0 0 0.16 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_57_1136" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_57_1136" result="shape" />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className=''>
            <div className='bg-white rounded-[10px] px-[15px] pt-[20px] pb-[24px] border-[1px] border-[#dcdada]'>
              <h5 className='text-[12px]'>Total Sales</h5>
              <div className='flex justify-between gap-4'>
                <h1 className='text-[24px] font-semibold'>{stat?.totalPurchase} Products</h1>
                <div className='flex space-x-1 mt-2 '>
                  <div className='mt-1'>
                    <svg width="85" height="41" viewBox="0 0 85 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_57_1136)">
                        <path d="M5 24.3152C7.01238 25.1501 12.3839 25.0894 17.7709 18.1671C24.5046 9.51427 30.774 11.7913 38.2043 21.5827C45.6347 31.3741 50.743 36.156 58.4056 22.7213C66.0681 9.2866 66.9969 4.73248 80 2" stroke="#279F51" stroke-width="3" />
                      </g>
                      <defs>
                        <filter id="filter0_d_57_1136" x="0.425171" y="0.532104" width="83.8833" height="39.9683" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0 0.941176 0 0 0 0.16 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_57_1136" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_57_1136" result="shape" />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div className=''>
            <div className='bg-white rounded-[10px] px-[15px] pt-[20px] pb-[24px] border-[1px] border-[#dcdada]'>
              <span className="flex justify-between">
                <h5 className='text-[12px]'>Total Revenue </h5>
                <span className=" cursor-pointer" onClick={() => setSee(!see)}>  <FaEye /></span>

              </span>

              <div className='flex justify-between gap-4'>
                <h1 className='text-[16px] font-semibold'>   {see ? (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format((stat?.totalRevenue) ? (stat?.totalRevenue) : 0.0)) : 'NGN *****'}</h1>
                <div className='flex space-x-1 mt-2 '>
                  <div className='mt-1'>
                    <svg width="85" height="41" viewBox="0 0 85 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_57_1136)">
                        <path d="M5 24.3152C7.01238 25.1501 12.3839 25.0894 17.7709 18.1671C24.5046 9.51427 30.774 11.7913 38.2043 21.5827C45.6347 31.3741 50.743 36.156 58.4056 22.7213C66.0681 9.2866 66.9969 4.73248 80 2" stroke="#FFA000" stroke-width="3" />
                      </g>
                      <defs>
                        <filter id="filter0_d_57_1136" x="0.425171" y="0.532104" width="83.8833" height="39.9683" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0 0.941176 0 0 0 0.16 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_57_1136" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_57_1136" result="shape" />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div className=''>
            <div className='bg-white rounded-[10px] px-[15px] pt-[20px] pb-[24px] border-[1px] border-[#dcdada]'>
              <h5 className='text-[12px]'>Total Products</h5>
              <div className='flex justify-between gap-4'>
                <h1 className='text-[24px] font-semibold'>{stat?.totalProduct}</h1>
                <div className='flex space-x-1 mt-2 '>
                  <div className='mt-1'>
                    <svg width="85" height="41" viewBox="0 0 85 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_57_1136)">
                        <path d="M5 24.3152C7.01238 25.1501 12.3839 25.0894 17.7709 18.1671C24.5046 9.51427 30.774 11.7913 38.2043 21.5827C45.6347 31.3741 50.743 36.156 58.4056 22.7213C66.0681 9.2866 66.9969 4.73248 80 2" stroke="#FF392B" stroke-width="3" />
                      </g>
                      <defs>
                        <filter id="filter0_d_57_1136" x="0.425171" y="0.532104" width="83.8833" height="39.9683" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0 0.941176 0 0 0 0.16 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_57_1136" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_57_1136" result="shape" />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>

        <div className="relative overflow-x-auto shadow-lg sm:rounded-lg mt-14 border-[2px]">
          <div className="flex justify-between p-3">
            <h1 className='text-[20px] font-semibold'>Purchases</h1>
            <NavLink className=" text-red-500 text-[13px] cursor-pointer" to={'/admin/view-purchase'}>See All</NavLink>
          </div>

          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray-700 bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  S/N
                </th>
                <th scope="col" className="px-6 py-3">
                  Order Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Customer
                </th>
                <th scope="col" className="px-6 py-3">
                  Product
                </th>
                <th scope="col" className="px-6 py-3">
                  Quantity
                </th>

                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>

                {/* <th scope="col" className="px-6 py-3">
                                        <span className="sr-only">Actions</span>
                                    </th> */}
              </tr>
            </thead>

            <tbody>
              {
                userLists?.data?.filter((data) => data?.role !== 'admin')?.map(
                  (datas, index) => (
                    <tr className="bg-white  ">

                      <td className="px-6 py-4">
                        {index + 1}
                      </td>

                      <td className="px-6 py-4">
                        {datas?.order_id}
                      </td>
                      <td className="px-6 py-4">
                        {datas?.customer_email}
                      </td>
                      <td className="px-6 py-4">
                        {datas?.product_name}
                      </td>
                      <td className="px-6 py-4">
                        {datas?.quantity}
                      </td>
                      <td className="px-6 py-4">
                        {(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format((datas?.price) ? (datas?.price) : 0.0))}

                      </td>
                      <td className="px-6 py-4">
                        {datas?.order_status == 'pending' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}
                        {datas?.order_status == 'paid' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}
                        {datas?.order_status == 'delievered' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}
                        {datas?.order_status == 'rejected' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#F5BFBF', color: '#E03130', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}
                        {datas?.order_status == 'InStore' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}
                        {datas?.order_status == 'Breakdown' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#FEF1E8', color: '#F97316', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}
                        {datas?.order_status == 'CheckedOut' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.order_status}</b></p> : ''}

                      </td>




                    </tr>
                  )
                )
              }

            </tbody>

          </table>
        </div>


        <div className="relative overflow-x-auto shadow-lg sm:rounded-lg border-[2px] mt-14">
          <div className="flex justify-between p-3">
            <h1 className='text-[20px] font-semibold'>Customers</h1>
            <NavLink className=" text-red-500 text-[13px] cursor-pointer" to={'/admin/customers'}>See All</NavLink>
          </div>

          <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
            <thead className="text-xs text-gray-700 bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  S/N
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Phone Number
                </th>
                <th scope="col" className="px-6 py-3">
                  Customer Id
                </th>

                <th scope="col" className="px-6 py-3">
                  Account Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Date Created
                </th>
              </tr>
            </thead>

            <tbody>
              {
                purchaseList?.data?.filter((data) => data?.role !== 'admin')?.map(
                  (datas, index) => (
                    <tr className="bg-white  ">

                      <td className="px-6 py-4">
                        {index + 1}
                      </td>

                      <td className="px-6 py-4">
                        {datas?.first_name + ' ' + datas?.last_name}
                      </td>
                      <td className="px-6 py-4">
                        {datas?.email}
                      </td>
                      <td className="px-6 py-4">
                        {datas?.phone_number}
                      </td>
                      <td className="px-6 py-4">
                        {datas?.id}
                      </td>

                      <td className="px-6 py-4">

                        {datas?.isVerified == 'true' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>Verified</b></p> : ''}
                        {datas?.isVerified == 'false' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#F5BFBF', color: '#E03130', borderRadius: '10px', padding: '2px 10px' }}>pending</b></p> : ''}



                      </td>


                      <td className="px-6 py-4">
                        {formatDateTime(datas?.created_at)}
                      </td>





                    </tr>
                  )
                )
              }

            </tbody>

          </table>
        </div>


      </div >


      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}
