import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import AdminNavbar from '../Navbars/AdminNavbar'
import CardEditDeal from '../Cards/CardEditDeal'

const EditDeal = () => {
  return (
    <div>
    <Sidebar title="Edit Deal"/>
<div className="relative md:ml-64 bg-white">
<AdminNavbar title="Edit Deal"/>
<div className="flex flex-wrap md:mt-4 mt-1">
  <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
    <CardEditDeal />
  </div>
</div>  
</div>
</div>
  )
}

export default EditDeal