import React from 'react'
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { AdminApis } from '../../apis/adminApi';
import { login } from '../../reducer/loginSlice'
import { formatDateTime } from '../Reusable/functions';
import { FaArrowLeft } from "react-icons/fa";

function CardCustomerList() {

    const userLoginData = useSelector((state) => state.data.login.value);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userLists, setUserList] = React.useState([]);
    const [searchText, setSearchText] = React.useState('');

    let [visible, setVisible] = React.useState(false);
    let [witdrawalData, setWitdrawalData] = React.useState([]);

    function proceed(data) {
        setWitdrawalData(data)
        setVisible(true)
    }


    React.useEffect(() => {
        if (!userLoginData?.id) {
            navigate('/sign-in');
        }
    }
    );



    React.useEffect(() => {
        const query = {
            search: searchText,
        };
        AdminApis.getAllCustomers('', query).then(
            (response) => {
                if (response?.data) {
                    setUserList(response?.data?.data)
                    // console?.log(response?.data?.data)
                } else {
                    dispatch(login([]))
                }
            }
        ).catch(function (error) {

        })
    }, []);



    const paginator = React.useCallback(
        (value) => {

            let value2 = '';
            if (value !== null) {
                value2 = value;
            } else {
                value2 = ''
            }
            const query = {
                search: searchText,
            };

            AdminApis.getAllCustomers(value2, query).then(
                (response) => {
                    if (response?.data) {
                        setUserList(response?.data?.data)
                    }
                }
            ).catch(function (error) {
                console.log(error.response.data);
            })

        }, [userLists, searchText]);

        const handleBackClick = () => {
            navigate(-1); // Go back to the previous page
          };

    return (
        <>


            <div className="relative  bg-white">
                <div className='py-4 lg:px-10 px-6 '>
                {/* <div className="flex justify-between items-center"> */}
                      {/* Back button */}
   <button type="button" onClick={handleBackClick} className="flex items-center text-gray-600 mb-4">
        <FaArrowLeft className="mr-2" />
        <span>Back</span>
      </button>
                    <h1 className='text-[30px] font-semibold'>Customers</h1>
                    <div className='flex justify-end'>
                        <div className=" lg:ml-auto mr-3 flex justify-end">
                            <div className="relative flex w-full flex-wrap items-stretch">
                                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                                </div>
                                <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border md:w-[20vw] border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                            </div>

                            <div className='mt-0.5 ml-2'><button type='button' onClick={(e) => paginator('')} className={"font-normal text-white bg-[#0071BC] px-3 py-0.5 rounded-md"}>Search</button> </div>



                        </div>

                        {/* <div className='mt-1'>Filter </div> */}
                    </div>




                    <div className="relative overflow-x-auto shadow-lg sm:rounded-lg mt-6">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                            <thead className="text-xs text-gray-700 bg-gray-50 ">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        S/N
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Email
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Phone Number
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Customer Id
                                    </th>

                                    <th scope="col" className="px-6 py-3">
                                        Account Status
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Date Created
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    userLists?.data?.filter((data) => data?.role !== 'admin')?.map(
                                        (datas, index) => (
                                            <tr className="bg-white  ">

                                                <td className="px-6 py-4">
                                                    {index + 1}
                                                </td>

                                                <td className="px-6 py-4">
                                                    {datas?.first_name + ' ' + datas?.last_name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.email}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.phone_number}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {datas?.id}
                                                </td>

                                                <td className="px-6 py-4">
                                                {datas?.isVerified == 'true' ? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>Verified</b></p> : ''}
                            {datas?.isVerified == 'false'? <p><b style={{ fontWeight: "400", fontSize: '15px', backgroundColor: '#F5BFBF', color: '#E03130', borderRadius: '10px', padding: '2px 10px' }}>pending</b></p> : ''}

                                                </td>


                                                <td className="px-6 py-4">
                                                    {formatDateTime(datas?.created_at)}
                                                </td>





                                            </tr>
                                        )
                                    )
                                }

                            </tbody>

                        </table>
                    </div>
                    <div className='m-4 flex justify-end'>
                        {
                            userLists?.links?.filter(((item, idx) => idx < 1000)).map(
                                (datas, index) => (
                                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-[#0071BC] text-white')}>
                                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                                    </button>
                                )
                            )
                        }

                    </div>




                </div>

            </div>




            <section>
                <Modal
                    visible={visible}
                    width="400"
                    height="200"
                    effect="fadeInUp"
                    onClickAway={() => setVisible(false)}
                >
                    <div className=" " style={{ height: '100%', overflow: 'auto' }}>
                        <span className="flex justify-end pr-2 pt-2">
                            <p className="cursor-pointer font-bold" onClick={(e) => setVisible(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                        </span>
                        <div className=" bg-[#fff]  items-center rounded-lg p-1 px-4">

                            <div className="">

                                <span className="flex justify-around">
                                    {/* <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1> */}

                                </span>

                                <label
                                    className="flex justify-start  mb-2 pt-2 text-md font-bold text-black"
                                >
                                    Are you sure you want to proceed with the Transfer?
                                </label>






                                <span className="flex justify-center pt-8">
                                    <button
                                        type="button"
                                        onClick={() => null}
                                        style={{ backgroundColor: '#0071BC', borderRadius: '50px' }}
                                        className=" text-white hover:bg-[#0071BC] focus:ring-4 focus:outline-none focus:ring-[#0071BC] font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                                    >
                                        Proceed to witdrawal
                                    </button>
                                </span>
                            </div>

                        </div>

                    </div>
                </Modal>
            </section>
        </>
    )
}

export default CardCustomerList