import { AxiosGlobal } from "../shared/axios";
import{ AxiosPromise} from "axios";
import configs from "../../configs";
import { store } from "../../store/store";



export class StoreLiveApis extends AxiosGlobal{

 
    createStore(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/stores/add-new`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    // createDeals(data: any): AxiosPromise<Array<any>> {
    //     return this.axios.post(`${configs.context}/deals/create-deal`, data, {
    //         headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    //     });
    // }

    // getDeals(): AxiosPromise<Array<any>> {
    //     return this.axios.get(`${configs.context}/deals/get-deals`, {
    //         headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
    //     });
    // }


    // getAllDeals(pageNo:any,query:any): AxiosPromise<Array<any>> {
    //     return this.axios.get(`${configs.context}/deals/get-all-deals?page=${pageNo}&search=${query?.search}`,{
    //         headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
    //       });
    // }


    updateStore(id: any, data:any): AxiosPromise<Array<any>> {
        return this.axios.put(`${configs.context}/stores/${id}/edit`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    
    getStore(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/stores/getAll `, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }
    

    getSingleStore(id:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/stores/${id}/getOne`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    deleteStore(id: any): AxiosPromise<Array<any>> {
        return this.axios.delete(`${configs.context}/stores/${id}/delete`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }


}