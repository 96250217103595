import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import AdminNavbar from "../Navbars/AdminNavbar";
import CardEditStore from "../Cards/CardEditStore";

const EditStore = () => {
  return (
    <>
      <Sidebar title="Edit Store" />
      <div className="relative md:ml-64 bg-white">
        <AdminNavbar title="Edit Store" />
        <div className="flex flex-wrap md:mt-4 mt-1">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
            <CardEditStore />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditStore;
