import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PromotionApis } from "../../../apis/promotionApis";

const CardCreatePromotions = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = React.useState(false);
  const [addPromotionImage, setAddPromotionImage] = useState([{ image: "" }]); // State to manage multiple courses

  const handleAddPromotionImage = () => {
    if (addPromotionImage.length < 5) {
      setAddPromotionImage([...addPromotionImage, { image: "" }]); // Add a new empty image object
    } else {
      toast.warn("You can only add up to 5 promotion images"); // Show a warning if more than 5 images
    }
  };

  const handleRemoveAddPromotionImage = (index: number) => {
    const newCourses = addPromotionImage.filter((_, i) => i !== index);
    setAddPromotionImage(newCourses);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const [count, setCount] = React.useState("");

  const [userData, setUserdata] = useState({
    promotion_name: "",
  });
  const [img1, setImg1] = React.useState("No selected file");
  const [img12, setImg12] = React.useState("empty");
  const uploadImg = (e: any, index: number) => {
    const file = e.target.files[0];
    let size = file.size / 1048576.0; // Convert size to MB

    if (file && size > 2) {
      toast.warn("Image too large");
      e.target.value = ""; // Clear the input value if the image is too large
      return;
    }

    if (file && size <= 2) {
      // Update the preview for the respective image
      const newImages: any = [...addPromotionImage];
      newImages[index].image = file; // Set the selected file in the state

      // Optional: set a temporary URL for image preview
      newImages[index].preview = URL.createObjectURL(file);

      setAddPromotionImage(newImages); // Update the state with the new image
    }
  };

  const createPromotion = React.useCallback(
    (e: any) => {
      e.preventDefault();
      if (addPromotionImage.some((img) => img.image === "")) {
        toast.error("Please upload all promotion images");
      } else {
        setLoader(true);

        // Prepare form data
        const formData = new FormData();
        formData.append("promotion_name", userData.promotion_name);
        addPromotionImage.forEach((prom: any, index: number) => {
          formData.append(`promotion_images[${index}]`, prom.image);
        });
        // console.log(formData)
        PromotionApis.createPromotion(formData)
          .then((response: any) => {
            if (response?.data) {
              setLoader(false);
              toast.success(response?.statusText);
              //   console.log(response)
              navigate("/admin/promotions");
            } else {
              toast.error(response?.response?.data?.message);
            }
          })
          .catch(function (error) {
            setLoader(false);
            toast.error(error.response.data.message);
          })
          .finally(function () {
            setLoader(false);
          });
      }
    },
    [userData, addPromotionImage]
  );

  return (
    <form onSubmit={createPromotion} className="pb-32 sm:px-3">
      <div className="flex items-center mt-4 justify-between">
        {/* Back button */}
        <button
          type="button"
          onClick={handleBackClick}
          className="flex items-center text-gray-600 mb-4"
        >
          <FaArrowLeft className="mr-2" />
          <span>Back</span>
        </button>
        <div className="md:flex md:justify-end">
          {/* <div className="bg-blue-100 md:w-2/5 rounded-lg m-1 p-2">
                <span className=" bg-blue-100  rounded-lg  text-gray-500 text-[12px]"><span className=" text-red-500 bg-red-200 p-1 px-3 rounded-full text-[15px]">!</span><br />  ATTENTION : Please kindly note that the market link is the link where your customers will see the list of your products</span>
              </div> */}
          <div className="flex justify-end gap-2 mt-5">
            <button
              type="submit"
              className=" text-white h-10 bg-[#0071BC] hover:bg-[#103f5e] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center "
            >
              Add Promotion
            </button>

            <Oval
              height={40}
              width={40}
              color="#0071BC"
              wrapperStyle={{}}
              wrapperClass=""
              visible={loader}
              ariaLabel="oval-loading"
              secondaryColor="#96cff6"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        </div>
      </div>

      <div
        className={
          "lg:grid lg:grid-cols-2 gap-2 mt-10 " +
          (loader ? "shadow animate-pulse " : "")
        }
      >
        <div className="flex flex-col gap-6 mb-10">
          <div className=" lg:w-4/5">
            <label
              htmlFor="promotion_name"
              className="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600"
            >
              Promotion Name
            </label>
            <input
              required
              type="text"
              //    defaultValue={title}
              onChange={handleChange}
              name="promotion_name"
              id="promotion_name"
              className="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              placeholder="Enter Promotion Name"
            />
          </div>

          {/* second Div */}
          <div className="grid grid-cols-2 gap-6">
            {addPromotionImage.map((image: any, index: number) => (
              <div key={index} className="">
                <label className="flex flex-col border items-center justify-center w-full rounded-[5px] cursor-pointer">
                  <div className="flex flex-col items-center justify-center">
                    {/* Use the preview if available, or a placeholder */}
                    {image.preview ? (
                      <img
                        src={image.preview}
                        alt="Preview"
                        style={{ minHeight: "200px", maxHeight: "200px" }}
                      />
                    ) : (
                      <img
                        src="/images/img1.png"
                        style={{ minHeight: "200px", maxHeight: "200px" }}
                      />
                    )}
                  </div>
                  <input
                    id="dropzone-file"
                    onChange={(e) => uploadImg(e, index)} // Pass the index for each image
                    accept="image/x-png,image/gif,image/jpeg"
                    type="file"
                    className="hidden mb-2 text-sm text-[#6C757D] font-medium"
                  />
                </label>
                {addPromotionImage.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveAddPromotionImage(index)}
                    className="text-red-500 mt-1"
                  >
                    Remove Promotion Image
                  </button>
                )}
              </div>
            ))}
          </div>

          {/* Button to add new image block, but disabled if there are already 5 images */}
          <button
            type="button"
            onClick={handleAddPromotionImage}
            className={`mb-4 bg-blue-500 text-white p-2 rounded disabled:bg-[#5c5c5c] ${
              addPromotionImage.length >= 5 ? " cursor-not-allowed" : ""
            }`}
            disabled={addPromotionImage.length >= 5}
          >
            {addPromotionImage.length >= 5
              ? "Max 5 Images Reached"
              : "Add Another Promotion Image"}
          </button>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </form>
  );
};

export default CardCreatePromotions;
