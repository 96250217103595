import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { PromotionApis } from "../../../apis/promotionApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-awesome-modal";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import { Oval } from "react-loader-spinner";
import { icontypesEnum, SvgElement } from "../../assets/svgElement";

const CardPromotions = () => {
  let [data, setdata] = React.useState<any>([]);
  const [loader, setLoader] = React.useState(true);
  let [value, setvalue] = React.useState("");
  let [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);
  let [visible, setVisible] = React.useState(false);

  let [valueStatus, setvalueStatus] = React.useState("");
  let [toggleStatusModal, setToggleStatusModal] = React.useState(false);
  // Load data when the component mounts or page refreshes
  React.useEffect(() => {
    setLoader(true);

    PromotionApis.getAllPromotions()
      .then((response) => {
        if (response?.data) {
          setdata(response?.data);
          setLoader(false);
          // console.log(response?.data);
        } else {
          // dispatch(login([]));
        }
      })
      .catch((error: any) => {
        console.log(error.response.data);
        setLoader(false);
      });
  }, []); // Trigger effect when 'effect' or URL changes

  function toggleDelete(value2: any) {
    setvalue(value2);

    setToggleDeleteModal(!visible);
  }

  function toggleStatus(value2: any) {
    setvalueStatus(value2);

    setToggleStatusModal(!visible);
  }

  const deletePromotion = React.useCallback(
    (e: any) => {
      e.preventDefault();
      setLoader(true);

      PromotionApis.deletePromotion(value)
        .then((response) => {
          if (response) {
            // console.log(response.data);
            setLoader(false);
            setToggleDeleteModal(false);
            toast.success("Deleted Successfully");

            // Refresh the list of promotions after deletion
            PromotionApis.getAllPromotions()
              .then((response) => {
                if (response?.data) {
                  setdata(response?.data); // Update the state with new data
                }
              })
              .catch((error: any) => {
                console.log(error.response.data);
                toast.error("Error fetching promotions");
              });
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
          toast.error("Offline");
        })
        .finally(() => {
          setLoader(false); // Stop loader when done
        });
    },
    [value]
  );

  const changePromotionStatus = React.useCallback(
    (id: any) => {
      setLoader(true);

      // Check if data is an array and find the current promotion
      const currentPromotion = Array.isArray(data?.promotion_images)
        ? data?.promotion_images.find((promo: any) => promo.id === id)
        : null;

      if (!currentPromotion) {
        toast.error("Promotion not found.");
        setLoader(false);
        return; // Exit the function if promotion is not found
      }

      const currentStatus = currentPromotion.status; // Get current status
      const newStatus = currentStatus === "active" ? "inactive" : "active"; // Toggle logic

      // If the new status is "active", check for existing active promotions
      if (newStatus === "active") {
        const activePromotion = data?.promotion_images.find(
          (promo: any) => promo.status === "active"
        );

        if (activePromotion) {
          toast.error("Only one promotion can be active at a time.");
          setLoader(false);
          return; // Exit if another active promotion exists
        }
      }

      // Update the promotion status using PATCH method
      PromotionApis.updatePromotionStatus(id, { status: newStatus })
        .then((response) => {
          if (response) {
            // console.log(response.data);
            setLoader(false);
            toast.success("Status changed Successfully");

            // Refresh the list of promotions after changing status
            PromotionApis.getAllPromotions()
              .then((response) => {
                if (response?.data) {
                  setdata(response.data); // Update the state with new data
                }
              })
              .catch((error: any) => {
                console.log(error.response.data);
                toast.error("Error fetching promotions");
              });
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
          toast.error("Error changing promotion status");
        })
        .finally(() => {
          setLoader(false); // Stop loader when done
        });
    },
    [data]
  );

  // Initialize data as an empty array

  return (
    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded">
      <div className="rounded-t mb-0  md:py-1 p-1 border-0">
        {/* <div className="bg-blue-100  rounded-lg ml-3 m-1 p-2 mb-3">
        <span className=" bg-blue-100  rounded-lg  text-gray-500 text-[12px]"><span className="mr-4 text-red-500 bg-red-200 p-1 px-3 rounded-full text-[15px]">!</span>Unlock a personalized shopping hub with Gupta's Mini Store. Tailor market links, showcase products, and enhance the shopping journey effortlessly.</span>
      </div> */}
        <div className="flex flex-wrap items-center">
          <div className="w-full px-4 max-w-full flex-grow flex-1">
            {/* {data?.data?.data?.length ? */}
            <span className="flex justify-end">
              {/* <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search</label> */}

              <NavLink
                to={"/admin/create-promotions"}
                className="flex justify-center"
              >
                <span className="flex justify-center ">
                  <button
                    type="button"
                    style={{ backgroundColor: "#0071BC", borderRadius: "50px" }}
                    className=" text-white hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-28 px-5 py-2.5 text-center "
                  >
                    + Add
                  </button>
                </span>
              </NavLink>
            </span>

            {/* // } */}

            <div>
              {
                !loader ? (
                  data?.promotion_images?.length >= 1 ? (
                    <div className="container  flex-col md:flex-row md:justify-start mt-1 pt-1 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-3">
                      {data?.promotion_images?.map((datas: any, index: any) => (
                        <div
                          className="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md"
                          key={datas?.id}
                        >
                          <hr />
                          <NavLink
                            to={`/edit-promotion/${datas?.id}`}
                            className={"cursor-pointer"}
                          >
                            <p
                              className="mb-2 tracking-tight m-2 p-2 bg-[#F4FBFF] h-44"
                              style={{
                                fontSize: "16px",
                                color: "#595959",
                                backgroundImage: `url(${datas?.promotion_images[0]})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                              }}
                            >
                              {/* {datas?.promotion_name} */}
                            </p>
                          </NavLink>
                          <hr />

                          <div className="flex flex-col pt-[16px] px-[16px] py-5">
                            <div className="flex items-center justify-between">
                              <span className="text-[16px] font-[600]">
                                {datas?.promotion_name}
                              </span>

                              <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                  type="checkbox"
                                  className="sr-only peer"
                                  checked={datas?.status === "active"}
                                  onChange={() =>
                                    changePromotionStatus(datas?.id)
                                  }
                                />
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                              </label>
                            </div>

                            <div className="flex items-center justify-between">
                              <div>
                                <NavLink
                                  to={`/edit-promotion/${datas?.id}`}
                                  className={"cursor-pointer pt-3"}
                                >
                                  <FaEdit />
                                </NavLink>
                              </div>

                              <div>
                                <button
                                  type="button"
                                  onClick={() => toggleDelete(datas?.id)}
                                  className="outline-none font-xs text-red-500 rounded-full text-xs px-2 py-2 text-center"
                                >
                                  <FaTrash />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded">
                      <div className="rounded-t mb-0  py-3 border-0">
                        <div className="flex flex-wrap items-center">
                          <div className="w-full px-4 max-w-full p-52 flex-grow flex-1">
                            <h3 className="flex justify-center font-bold">
                              {" "}
                              You haven’t created any Promotion
                            </h3>
                            <p className="flex text-sm justify-center">
                              {" "}
                              Click on the button below to create a new{" "}
                            </p>
                            <p className="flex text-sm justify-center text-black font-bold">
                              {" "}
                              Promotion.
                            </p>

                            <NavLink
                              to="/admin/create-promotions"
                              className="flex justify-center"
                            >
                              <span className="flex justify-center pt-4">
                                <button
                                  type="submit"
                                  style={{
                                    backgroundColor: "#0071BC",
                                    borderRadius: "50px",
                                  }}
                                  className=" text-white hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-40 px-5 py-2.5 text-center "
                                >
                                  + Create New
                                </button>
                              </span>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                      <div className="block w-full overflow-x-auto">
                        {/* Projects table */}
                      </div>
                    </div>
                  )
                ) : (
                  <div
                    className="p-2  shadow animate-pulse md:p-6 dark:border-gray-700"
                    style={{ height: "70vh", width: "78vw" }}
                  >
                    <div className="flex justify-center items-center mb-4 h-48 bg-gray-300 rounded dark:bg-gray-400"></div>
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-48 mb-4"></div>
                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5"></div>
                    <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-400"></div>
                    <div className="flex items-center mt-4 space-x-3"></div>
                    <span className="sr-only">Loading...</span>
                  </div>
                )
                //  :
                //  <div>
                //   <h2>Pending </h2>
                //  </div>
              }
            </div>

            {/* <div className='m-4 mt-10 flex justify-end'>
            {
              data?.data?.links?.filter(((item, idx) => idx < 1000)).map(
                (datas, index) => (
                  <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-[#0071BC] text-white')}>
                    {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                  </button>
                )
              )
            }

          </div> */}
          </div>
        </div>
      </div>
      <div className="block w-full overflow-x-auto">{/* Projects table */}</div>

      <section>
        <Modal
          visible={toggleDeleteModal}
          width="350"
          height="430"
          effect="fadeInUp"
          onClickAway={() => setToggleDeleteModal(false)}
        >
          <div className=" " style={{ height: "100%", overflow: "auto" }}>
            <div className="container flex flex-row justify-around bg-[#fff]  items-center rounded-lg p-2">
              <div className="px-3">
                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-end px-2 pt-3">
                  <p
                    className="cursor-pointer font-bold"
                    onClick={(e) => setToggleDeleteModal(false)}
                  >
                    <SvgElement type={icontypesEnum.CANCEL} />
                  </p>
                </span>

                <label className="flex justify-start mb-2 pt-1 text-md font-bold text-black">
                  Delete Promotion
                </label>

                <label
                  style={{ fontSize: "14px" }}
                  className="flex justify-start mb-2 pt-2 text-xs font-medium text-gray-600"
                >
                  You are about to delete the Promotion you created.
                </label>
                <label
                  style={{ fontSize: "14px" }}
                  className="flex justify-start mb-2 pt-2 text-xs font-medium text-gray-600"
                >
                  Please note that:
                </label>

                <ul className="space-y-1 max-w-md list-disc list-inside text-gray-500 dark:text-gray-400 pl-2">
                  <li
                    style={{ color: "#2C2C2C", fontSize: "14px" }}
                    className="text-xs"
                  >
                    This Promotion data will be lost
                  </li>
                </ul>

                <form onSubmit={deletePromotion} className="pb-4 rounded-lg">
                  <span className="flex justify-center pt-4">
                    <button
                      type="submit"
                      style={{ borderRadius: "50px", color: "#F52424" }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Delete Promotion
                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <button
                      type="button"
                      onClick={(e) => setToggleDeleteModal(false)}
                      style={{ borderRadius: "50px" }}
                      className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                    >
                      Cancel
                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <Oval
                      height={40}
                      width={40}
                      color="#0071BC"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={loader}
                      ariaLabel="oval-loading"
                      secondaryColor="#96cff6"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </span>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </section>

      <section>
        <Modal
          visible={toggleStatusModal}
          width="350"
          height="430"
          effect="fadeInUp"
          onClickAway={() => setToggleStatusModal(false)}
        >
          <div className=" " style={{ height: "100%", overflow: "auto" }}>
            <div className="container flex flex-row justify-around bg-[#fff]  items-center rounded-lg p-2">
              <div className="px-3">
                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-end px-2 pt-3">
                  <p
                    className="cursor-pointer font-bold"
                    onClick={(e) => setToggleDeleteModal(false)}
                  >
                    <SvgElement type={icontypesEnum.CANCEL} />
                  </p>
                </span>

                <label className="flex justify-start mb-2 pt-1 text-md font-bold text-black">
                  Change Promotion Status
                </label>

                <label
                  style={{ fontSize: "14px" }}
                  className="flex justify-start mb-2 pt-2 text-xs font-medium text-gray-600"
                >
                  You are about to change the status of this promotion
                </label>

                <form
                  onSubmit={changePromotionStatus}
                  className="pb-4 rounded-lg"
                >
                  <span className="flex justify-center pt-4">
                    <button
                      type="submit"
                      style={{ borderRadius: "50px" }}
                      className=" text-blue-700 bg-blue-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Change Status
                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <button
                      type="button"
                      onClick={(e) => setToggleStatusModal(false)}
                      style={{ borderRadius: "50px" }}
                      className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                    >
                      Cancel
                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <Oval
                      height={40}
                      width={40}
                      color="#0071BC"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={loader}
                      ariaLabel="oval-loading"
                      secondaryColor="#96cff6"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </span>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default CardPromotions;
