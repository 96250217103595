import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import AdminNavbar from "../Navbars/AdminNavbar";
import CardEditPromotion from "../Cards/promotions/CardEditPromotion";

const EditPromotion = () => {
  return (
    <div>
      <Sidebar title="Edit Promotion" />
      <div className="relative md:ml-64 bg-white">
        <AdminNavbar title="Edit Promotion" />
        <div className="flex flex-wrap md:mt-4 mt-1">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
            <CardEditPromotion />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPromotion;
