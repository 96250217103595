
import React, { useState } from "react";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-awesome-modal';
import { PhoneInput } from "react-contact-number-input";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { AdminApis } from "../../apis/adminApi";
import { FaTrash, FaEdit } from "react-icons/fa";
import { AiOutlineWarning } from "react-icons/ai";
import EmojiPicker from 'emoji-picker-react';
import { useParams } from 'react-router-dom';
import { MutatingDots } from 'react-loader-spinner'
import { ThreeDots } from 'react-loader-spinner' 
import { TailSpin } from 'react-loader-spinner'
import { Oval } from 'react-loader-spinner'
import { CategoryApis } from "../../apis/CategoryApis";
import { FaArrowLeft } from "react-icons/fa";

// components



export default function CardEditCategory() {
  const navigate = useNavigate();
  let [visible, setVisible] = React.useState(false);
  const [addLink, setAddLink] = React.useState([]);

  const [addContact, setAddContact] = React.useState([]);

  const [productLink, setProductLink] = React.useState([]);
  const params = useParams();

  const [productName, setProductName] = useState('');
  const [productId, setProductId] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [noOfItems, setNoOfItems] = useState('');
  const [category, setCategory] = useState('');
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState('');

  const [imgId1, setImgId1] = useState('');
  const [imgId2, setImgId2] = useState('');
  const [imgId3, setImgId3] = useState('');


  




  function toggleModal() {
    setVisible(!visible)
  }

  // setLinkName(addLink.split(" "))
  // console?.log((addLink.split(" "))[1])




  const [img1, setImg1] = React.useState('No selected file');
  const [img12, setImg12] = React.useState('empty');
  function uploadImg1(e) {
    let size = (e.target.files[0].size / 1048576.0)
    setImg12(URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      if (size > 4) {
        if (e.target.name == 'uploadImg1') {
          e.target.value = ''
        }
      }
      if (size <=4) {
        if (e.target.name == 'uploadImg1') {
          setImg1(e.target.files[0]);
        }
      }
    }
  };


  const [img2, setImg2] = React.useState('No selected file');
  const [img22, setImg22] = React.useState('empty');
  function uploadImg2(e) {
    let size = (e.target.files[0].size / 1048576.0)
    setImg22(URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      if (size > 2) {
        if (e.target.name == 'uploadImg2') {
          e.target.value = ''
        }
      }
      if (size <= 2) {
        if (e.target.name == 'uploadImg2') {
          setImg2(e.target.files[0]);
        }
      }
    }
  };

  const [img3, setImg3] = React.useState('No selected file');
  const [img32, setImg32] = React.useState('empty');
  function uploadImg3(e) {
    let size = (e.target.files[0].size / 1048576.0)
    setImg32(URL.createObjectURL(e.target.files[0]))
    if (e.target.files && e.target.files[0]) {
      if (size > 2) {
        if (e.target.name == 'uploadImg3') {
          e.target.value = ''
        }
      }
      if (size <= 2) {
        if (e.target.name == 'uploadImg3') {
          setImg3(e.target.files[0]);
        }
      }
    }
  };

  const [loader, setLoader] = React.useState(false);
  let [data, setdata] = React.useState(0);
  let [checkLink, setCheckLink] = React.useState('');

 
  React.useEffect(() => {
    CategoryApis.getSingleCategory(params?.id).then(
      (response) => {
        if (response?.data) {
          console?.log(response?.data?.data)
          setProductName(response?.data?.data?.cat_name);
          setImg1(response?.data?.data?.cat_image)
          setImg12(response?.data?.data?.cat_image)
          setImgId1(response?.data?.data?.cat_image_id)
          setProductId(response?.data?.data?.id)
        }
      }
    );

  }, []);


 


  const createProduct = React.useCallback(
    (e) => {
      e.preventDefault();
      setLoader(true);
      const formData = new FormData()
      formData.append('cat_name', productName)
      formData.append('cat_image', img1)
      formData.append('cat_image_id', imgId1)
      formData.append('id', productId)


      CategoryApis.updateCategory(formData).then(
        (response) => {
          if (response?.data) {
            // console.log(response?.data)
            // toggleModal()
            setLoader(false);
            toast.success("Category Updated Successfully");
          } else {
            // toggleModal()
            toast.error('Something went wrong');

          }

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        // console.log(error.response);
        toast.error(error.response.data.message);
      })
    },
    [productName, img1, productId, imgId1]
  );

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <>

      <form onSubmit={createProduct} className="pb-32 sm:px-5">


<div className="flex justify-between items-center">
                      {/* Back button */}
   <button type="button" onClick={handleBackClick} className="flex items-center text-gray-600 mb-4">
        <FaArrowLeft className="mr-2" />
        <span>Back</span>
      </button>
        <div className="flex justify-end gap-2 mt-5">
          {/* <button
            type="button"
            onClick={toggleModal}
            className=" text-gray-900 bg-[#8ed2ff] hover:bg-[#167bbe] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center "
          >
            Add Market Link
          </button> */}
          {
            loader ?
              <div className='flex justify-center '>
                <Oval
                  height={40}
                  width={40}
                  color="#0071BC"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={loader}
                  ariaLabel='oval-loading'
                  secondaryColor="#96cff6"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
              :
              ''
          }

          <button
            type="submit"
            className=" text-white bg-[#0071BC] hover:bg-[#103f5e] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center "
          >
            Update Category 
          </button>
        </div>
        </div>

        <div className={"lg:grid lg:grid-cols-2 gap-2 mt-20 "+(loader?'shadow animate-pulse ':'')}>
          <div className="mb-10">
            <div className=" lg:w-4/5">
              <label for="first_name" class="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600">Product Name</label>
              <input type="text" defaultValue={productName} onChange={(e) => setProductName(e?.target?.value)} id="first_name" class="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Title of business here" />
            </div>

          </div>

          {/* second Div */}
          <div className="grid grid-cols-1 gap-2">
            <div className="">
              <label className="flex flex-col items-center justify-center w-full  rounded-[5px] cursor-pointer ">
                <div className="flex flex-col items-center justify-center  ">
                  {img12 == 'no image' ? <img src="/images/img1.png" style={{ minHeight: '200px', maxHeight: "200px" }} /> : <img src={img12} style={{ minHeight: '200px', maxHeight: "200px" }} />}
                </div>
                <input id="dropzone-file" onChange={uploadImg1} accept="image/x-png,image/gif,image/jpeg" name='uploadImg1' type="file" className={"hidden mb-2 text-sm text-[#6C757D] font-medium"} />
              </label>
            </div>

           


          </div>

        </div>


      </form>





   

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}
