import React, { useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner";
import { FaArrowLeft } from "react-icons/fa";
import { StoreApis } from "../../apis/storeApis";
import { useSelector, useDispatch } from "react-redux";
import {
  useLoadScript,
  StandaloneSearchBox,
  LoadScriptProps,
} from "@react-google-maps/api";

const libraries: LoadScriptProps["libraries"] = ["places"];

const CreateCardStore = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");

  const [userData, setUserdata] = useState({
    name: "",
    address: "",
    // 'gender': "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  const [loader, setLoader] = React.useState(false);

  const createProduct = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();

      setLoader(true);
      const formData = new FormData();
      // formData.append('user_id', userLoginData?.id)
      formData.append("name", userData?.name);
      formData.append("address", address);
      StoreApis.createStore(formData)
        .then((response) => {
          if (response?.data) {
            // console.log(response?.data)
            // toggleModal()
            setLoader(false);
            toast.success(response?.data?.message);
            navigate("/admin/stores");
          } else {
            // toggleModal()
            toast.error(response?.data?.message);
          }

          // toast.success(response?.data?.message);
        })
        .catch(function (error) {
          // handle error
          // console.log(error.response);
          setLoader(false);
          toast.error(error.response.data.message);
        })
        .finally(function () {
          setLoader(false);
        });
    },
    [address]
  );

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA8uPmEdNIXnG7TXLMf2iECVp5wAmXczeY", // Replace with your actual API key
    libraries,
  });

  const [suggestions, setSuggestions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

  if (!isLoaded) return <div>Loading...</div>;
  if (loadError) return <div>Error loading maps</div>;

  const handleLoad = (searchBox: google.maps.places.SearchBox) => {
    searchBoxRef.current = searchBox;
  };

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current?.getPlaces();
    if (places && places.length > 0) {
      setAddress(places[0].formatted_address || "");
      setSuggestions([]);
    }
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setAddress(input);

    if (input.length > 2) {
      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        { input },
        (predictions, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setSuggestions(predictions);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };
  return (
    <>
      <form onSubmit={createProduct} className="pb-32 sm:px-3">
        <div className="flex justify-between items-center">
          {/* Back button */}
          <button
            type="button"
            onClick={handleBackClick}
            className="flex items-center text-gray-600 mb-4"
          >
            <FaArrowLeft className="mr-2" />
            <span>Back</span>
          </button>
          <div className="md:flex md:justify-end">
            {/* <div className="bg-blue-100 md:w-2/5 rounded-lg m-1 p-2">
          <span className=" bg-blue-100  rounded-lg  text-gray-500 text-[12px]"><span className=" text-red-500 bg-red-200 p-1 px-3 rounded-full text-[15px]">!</span><br />  ATTENTION : Please kindly note that the market link is the link where your customers will see the list of your products</span>
        </div> */}

            <div className="flex justify-end gap-2 mt-5">
              <button
                type="submit"
                className=" text-white h-10 bg-[#0071BC] hover:bg-[#103f5e] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center "
              >
                Add Store
              </button>

              <Oval
                height={40}
                width={40}
                color="#0071BC"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loader}
                ariaLabel="oval-loading"
                secondaryColor="#96cff6"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          </div>
        </div>

        <div
          className={
            "lg:grid lg:grid-cols-2 gap-2 mt-10 " +
            (loader ? "shadow animate-pulse " : "")
          }
        >
          <div className="mb-10">
            <div className=" lg:w-4/5">
              <label
                htmlFor="name"
                className="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600"
              >
                Store Name
              </label>
              <input
                required
                type="text"
                onChange={handleChange}
                id="name"
                name="name"
                className="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                placeholder="Enter Store Name"
              />
            </div>

            <div className="mt-10 lg:w-4/5">
              <StandaloneSearchBox
                onLoad={handleLoad}
                onPlacesChanged={handlePlacesChanged}
              >
                <div className="relative mb-6">
                  <label className="block mb-2 text-sm font-semibold text-[#414143]">
                    Address
                  </label>

                  <textarea
                    className="bg-[#FBFBFB] border border-[#00000040] text-[#333333] text-sm rounded-[7px] block w-full p-2 placeholder:text-[#00000040] placeholder:text-[12px]  ease-linear transition-all duration-150"
                    placeholder="Enter Store Address"
                    name="address"
                    required
                    value={address}
                    onChange={(e: any) => handleInputChange(e)}
                  />
                </div>
              </StandaloneSearchBox>
              {suggestions.length > 0 && (
                <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto">
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion.place_id}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                      onClick={() => {
                        setAddress(suggestion.description);
                        setSuggestions([]);
                      }}
                    >
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </form>

      {/* CREATE MARKET LINK */}

      {/* DELETE MARKET LINK */}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default CreateCardStore;
