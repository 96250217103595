import { AxiosGlobal } from "../shared/axios";
import{ AxiosPromise} from "axios";
import configs from "../../configs";
import { store } from "../../store/store";



export class PromotionLiveApis extends AxiosGlobal{

 
    createPromotion(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/promotions/add-new`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getAllPromotions(): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/promotions/getAll`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }
    
    updatePromotion(id: any, data: { promotion_name: string; images: any[] }): AxiosPromise<Array<any>> {
        const formData = new FormData();
        formData.append("promotion_name", data.promotion_name);
    
        data.images.forEach((image, index) => {
            if (image.action === "add" || image.action === "replace") {
                formData.append(`images[${index}][action]`, image.action);
                formData.append(`images[${index}][image]`, image.image); // File object
                if (image.position !== undefined) {
                    formData.append(`images[${index}][position]`, String(image.position));
                }
            } else if (image.action === "remove") {
                formData.append(`images[${index}][action]`, "remove");
                formData.append(`images[${index}][url]`, image.url);
            }
        });
    
        return this.axios.put(`${configs.context}/promotions/${id}/edit`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${store.getState().data.login.value.token}`,
            },
        });
    }
    

    updatePromotionStatus(id:any, status:any): AxiosPromise<Array<any>> {
        return this.axios.patch(`${configs.context}/promotions/${id}/change-status`, status, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getSinglePromotion(id: any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/promotions/${id}/getOne`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }
  
    deletePromotion(id: any): AxiosPromise<Array<any>> {
        return this.axios.delete(`${configs.context}/promotions/${id}/delete`, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }
}