import React, { useEffect, useRef, useState } from 'react';
import Categories from './Categories';
import MostViewed from './Groups/MostViewed';
import { ProductApis } from '../apis/productApis';
import { AxiosResponse } from 'axios';
import { SvgElement, icontypesEnum } from './assets/svgElement';
import { Link, NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FaEye, FaWhatsapp } from 'react-icons/fa';
import configs from '../configs';
import Modal from 'react-responsive-modal';
import { PaymentApis } from '../apis/paymentApis';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from "redux";
import { login } from '../reducer/loginSlice'

//@ts-ignore
import { PhoneInput } from 'react-contact-number-input';
import { AuthApis } from '../apis/authApis';
import { CartApis } from '../apis/cartApis';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { hideAll } from 'tippy.js';
import UserSidebar from './Sidebar/UserSidebar';
// import Navbar from './Navbars/AdminNavbar';
import Sidebar from './Sidebar/Sidebar';
import { IoArrowBack } from 'react-icons/io5';
import { AiOutlineSetting } from 'react-icons/ai';
import { CategoryApis } from '../apis/CategoryApis';
import Footer from './Footers/Footer';
import { Oval } from 'react-loader-spinner';
import Navbar from './Navbars/Navbar';

function Feedback() {

  const userLoginData = useSelector((state: any) => state.data.login.value);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const dispatch: Dispatch = useDispatch();

  const [products, setProducts] = React.useState<any>([]);
  const [loc, setLoc] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [fetchCategory, setFetchCategory] = React.useState('');
  const [loader, setLoader] = React.useState<boolean>(false);
  const [location, setLocation] = React.useState('');

  const [collapseShow, setCollapseShow] = React.useState("hidden");

  let [target, setTarget] = React.useState<Element>();

  let [visible, setVisible] = React.useState(false);
  let [value, setvalue] = React.useState<any>('');



  const params = useParams();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()



  const [name, setname] = React.useState('');
  const [total2, setTotal2] = React.useState<any>('');
  const [total, setTotal] = React.useState<any>('');
  React.useEffect(() => {
    CartApis.getCartCount().then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setname(response?.data?.cart);
          setTotal2(response?.data?.total)
        } else {
          dispatch(login([]))
        }
      }
    )


    CategoryApis.getCategory('').then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setTotal(response?.data?.data)
        } else {
          dispatch(login([]))
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
    })

  }, []);



  if (searchParams.get('status') == 'cancelled') {
    navigate(`/store/${params?.storeId}`);
  }


  React.useEffect(() => {
    setLoader(true);
    const query: any = {
      search: search,
      name: loc,
      categories: category
    };
    ProductApis.getAllProducts('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setProducts(response.data)
          setLoader(false);

          // console.log(response?.data?.data);
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
    })

  }, [category]);


  const logOut = () => {
    dispatch(login([]))
    AuthApis.logout('').then(
      (response: AxiosResponse<any>) => {
        if (response) {
          navigate('/');

        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
      console.log("new error");
    })

  };



  const paginator = React.useCallback(
    (value: any) => {
      setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query: any = {
        search: search,
        name: loc,
        categories: category
      };
      ProductApis.getAllProducts(value2, query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setProducts(response.data)
            setLoader(false);
            setFetchCategory(search !== '' ? search : category)
          }
        }
      ).catch(function (error: any) {
        // handle error
        console.log(error.response.data);
        console.log("new error");
      })

    }, [search, loc, category, fetchCategory]);




  const [name2, setName2] = useState('');
  const [email, setEmail] = useState('');
  const [rating, setRating] = useState(5);
  const [comments, setComments] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setVisible(true);
    // Here you can handle the form submission, e.g., send the feedback to an API
    console.log({ name2, email, rating, comments });
    let data = {
      "name": name2,
      "email": email,
      "rating": rating,
      "comments": comments
    }
    CartApis.submitFeedback(data).then(
      (response) => {
        if (response?.data) {
          setVisible(false);
          alert('Feedback submitted successfully!');
        }
      }
    ).catch(function (error) {
      // handle error
      console.log(error.response.data);
      toast.error("Offfline");
    }).finally(() => {
      // window.location.reload();
      //toast.error("No Internet Connection");

    });

    setName2('');
    setEmail('');
    setRating(5);
    setComments('');
  };


  const [dea, setDea] = React.useState<any>([]);
  const [searc, setSearc] = React.useState<any>('');
  const [loade, setLoade] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoader(true);
    const query = {
      search: searc,
      name: '',
      categories: ''
    };
    ProductApis.getAllDeals('', query).then(
      (response) => {
        if (response?.data) {
          setDea(response.data)
          setLoade(false);
        }
      }
    ).catch(function (error) {
      // handle error
      console.log(error.response.data);
    })

  }, []);

  return (
    <div className=''>
 <Navbar />



      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-6">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-bold mb-4 text-center">Feedback Form</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                value={name2}
                onChange={(e) => setName2(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">Rating</label>
              <select
                value={rating}
                onChange={(e) => setRating(Number(e.target.value))}
                className="w-full p-2 border border-gray-300 rounded mt-1"
              >
                <option value="1">1 - Very Poor</option>
                <option value="2">2 - Poor</option>
                <option value="3">3 - Average</option>
                <option value="4">4 - Good</option>
                <option value="5">5 - Excellent</option>
              </select>
            </div>
            <div>
              <label className="block text-gray-700">Comments</label>
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                rows={4}
              />
            </div>
            <button
              type="submit"
              className="w-full flex justify-center gap-2 bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
            >
              <span> Submit Feedback</span>
              <span className='mt-1'>
              <Oval
                visible={visible}
                height="20"
                width="20"
                color="#0071DC"
                secondaryColor="#E6F1FC"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
              </span>

             

             
            </button>
          </form>
        </div>
      </div>


      <Footer />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

    </div>
  );
}

export default Feedback;
