import React from "react";
import Sidebar from "../../Sidebar/Sidebar";
import AdminNavbar from "../../Navbars/AdminNavbar";
import CardPromotions from "../../Cards/promotions/CardPromotions";

const Promotions = () => {
  return (
    <div>
      <Sidebar title="Promotions" />
      <div className="relative md:ml-64 bg-white">
        <AdminNavbar title="Promotions" />
        <div className="flex flex-wrap mt-1">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-1">
            <CardPromotions />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotions;
