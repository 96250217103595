
import React, { useState } from "react";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-awesome-modal';
import { PhoneInput } from "react-contact-number-input";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { AdminApis } from "../../apis/adminApi";
import { FaTrash, FaEdit } from "react-icons/fa";
import { AiOutlineWarning } from "react-icons/ai";
import EmojiPicker from 'emoji-picker-react';
import { Oval } from 'react-loader-spinner'
import InputColor from 'react-input-color';
import { CategoryApis } from "../../apis/CategoryApis";
import { formatDateTime } from "../Reusable/functions";

// components



export default function CardDeals() {
  const navigate = useNavigate();
  const [total, setTotal] = useState([]);
  const [deals, setDeals] = useState([]);
  const [singleDeals, setSingleDeals] = useState([]);


  let [dealName, setDealName] = React.useState('');
  let [percentDiscount, setPercentDiscount] = React.useState('');
  let [cat1, setCat1] = React.useState('');
  let [cat2, setCat2] = React.useState('');
  let [cat3, setCat3] = React.useState('');
  let [cat4, setCat4] = React.useState('');
  let [startDate, setStartDate] = React.useState('');
  let [endDate, setEndDate] = React.useState('');


  React.useEffect(() => {
    CategoryApis.getCategory('').then(
      (response) => {
        if (response?.data) {
          setTotal(response?.data?.data)
        }
      }
    )


    CategoryApis.getDeals('').then(
      (response) => {
        if (response?.data) {
          setDeals(response?.data)
          setDealName(response?.data?.data?.name)
          setPercentDiscount(response?.data?.data?.percent_discount)
          setCat1(response?.data?.data?.cat_1)
          setCat2(response?.data?.data?.cat_2)
          setCat3(response?.data?.data?.cat_3)
          setCat4(response?.data?.data?.cat_4)
          setStartDate(response?.data?.data?.start_date)
          setEndDate(response?.data?.data?.end_date)
        }
      }
    )

  }, []);


  const [loader, setLoader] = React.useState(false);

  const [addCategory, setAddCategory] = useState([{ category: '' }]);
  const [productIds, setProductIds] = useState([]); // State to collect all selected categories as product_ids
  let [value, setvalue] = React.useState('');
  let [toggleDeleteModal, setToggleDeleteModal] = React.useState(false);
  let [visible, setVisible] = React.useState(false);

  function toggleDelete(value2) {
    setvalue(value2)

    setToggleDeleteModal(!visible)
  }

  React.useEffect(() => {
    CategoryApis.getSingleDeals(value).then(
      (response) => {
        if (response?.data) {
          // console.log(response.data)
          setSingleDeals(response?.data)
         
        }
      }
    )

  }, [value]);


  const deleteDeals = React.useCallback(
    (e) => {
      e.preventDefault();
      setLoader(true);
  
      CategoryApis.deleteDeals(value)
        .then((response) => {
          if (response) {
            // console.log(response.data);
            setLoader(false);
            setToggleDeleteModal(false);
            toast.success("Deleted Successfully");
  
            // Refresh the list of promotions after deletion
            CategoryApis.getDeals().then((response) => {
              if (response?.data) {
                setDeals(response?.data); // Update the state with new data
              }
            }).catch((error) => {
              console.log(error.response.data);
              toast.error("Error fetching promotions");
            });
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
          toast.error("Offline");
        })
        .finally(() => {
          setLoader(false); // Stop loader when done
        });
    },
    [value]
  );
  // const UpdateMarketLink = React.useCallback(
  //   (e) => {
  //     // console?.log(color.hex)
  //     e.preventDefault();
  //     const formData = new FormData()
  //     formData.append('link_name', marketLinkData?.link_name)
  //     formData.append('brand_primary_color', (color?.hex) ? color?.hex : marketLinkData?.brand_primary_color)
  //     formData.append('brand_description', brandDescription == '' ? marketLinkData?.brand_description : brandDescription)
  //     formData.append('facebook_url', facebookUrl == '' ? marketLinkData?.facebook_url : facebookUrl)
  //     formData.append('instagram_url', instagramUrl == '' ? marketLinkData?.instagram_url : instagramUrl)
  //     formData.append('tiktok_url', tiktokUrl == '' ? marketLinkData?.tiktok_url : tiktokUrl)
  //     formData.append('brand_logo', logoImg)
  //     formData.append('brand_logo_id', logoImg)
  //     formData.append('id', marketLinkData?.id)

  //     AdminApis.updateMarketLink(formData).then(
  //       (response) => {
  //         if (response?.data) {
  //           // console.log(response?.data)
  //           setRefresh(!refresh)
  //           setToggleEditMarketLink(false);
  //           setBrandDescription('')
  //           setFacebookUrl('')
  //           setInstagramUrl('')
  //           setTiktokUrl('')
  //           setLogoImg("No selected file")
  //           setColor('');
  //           toast.success(response?.data?.message);
  //         } else {
  //           toggleModal()
  //           toast.error(response?.response?.data?.message);

  //         }

  //         // toast.success(response?.data?.message);
  //       }
  //     ).catch(function (error) {
  //       // handle error
  //       // console.log(error.response);
  //       toast.error(error.response.data.message);
  //     })
  //   },
  //   [checkLink, color, brandDescription, facebookUrl, instagramUrl, tiktokUrl, refresh, logoImg]
  // );



  // const createProduct = React.useCallback(
  //   (e) => {
  //     e.preventDefault();

  //     setLoader(true)
  //     const formData = {
  //       "name":dealName,
  //       "percent_discount":percentDiscount,
  //       "cat_1":cat1,
  //       "cat_2":cat2,
  //       "cat_3":cat3,
  //       "cat_4":cat4,
  //       "start_date":startDate,
  //       "end_date":endDate,
  //       'id':1
  //     }
  //     CategoryApis.createDeals(formData).then(
  //       (response) => {
  //         if (response?.data) {
  //           setLoader(false)
  //           toast.success(response?.data?.message);
  //           // navigate('/admin/deals');
  //         } else {
  //           // toggleModal()
  //           toast.error(response?.response?.data?.message);
  //         }

  //         // toast.success(response?.data?.message);
  //       }
  //     ).catch(function (error) {
  //       // handle error
  //       // console.log(error.response);
  //       setLoader(false)
  //       toast.error(error.response.data.message);
  //     }).finally(function () {
  //       setLoader(false)
  //     })

  //   },
  //   [dealName,percentDiscount,cat1,cat2,cat3,cat4,startDate,endDate, productIds]
  // );


  return (
    <>

<div className="bg-white">
        <div className="py-4 lg:px-10 px-6 ">
          <h1 className="text-[30px] font-semibold">Deals</h1>
          <div className="flex justify-end">
            <div className=" mr-3 flex justify-end">
              <div className="relative flex w-full flex-wrap items-stretch">
                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#9da4aa"
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z"
                    />
                  </svg>
                </div>
                {/* <input type="text" onClick={() => ('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required /> */}
              </div>

              {/* <div className='mt-0.5 ml-2'><button type='button' onClick={(e) => paginator('')} className={"font-normal text-white bg-[#0071BC] px-3 py-0.5 rounded-md"}>Search</button> </div> */}
              <div className="mt-0.5 ml-2">
                <NavLink to={"/admin/create-deals"}>
                  <button
                    type="button"
                    // onClick={(e) => setVisible(true)}
                    className={
                      "font-normal text-white bg-[#0071BC] px-3 py-0.5 rounded-md"
                    }
                  >
                    Create
                  </button>
                </NavLink>
              </div>
            </div>

            {/* <div className='mt-1'>Filter </div> */}
          </div>

          <div className="shadow-lg sm:rounded-lg w-full mt-6">
            <table className=" text-sm w-full text-gray-500 ">
              {/* <div className='w-full '> */}
              <thead className="text-xs text-gray-700 w-full px-5 bg-gray-50 ">
                <tr className="w-full">
                  <th scope="" className=" py-3">
                    S/N
                  </th>
                  <th scope="" className=" py-3">
                    Deal Name
                  </th>
                  <th scope="" className=" py-3">
                    Time Created
                  </th>
                  <th scope="" className=" py-3">
                    Percent Discount
                  </th>
                  <th scope="" className=" py-3">
                    Start Date
                  </th>
                  <th scope="" className=" py-3">
                   End Date
                  </th>
                  <th scope="" className=" py-3">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className="">
                {deals.deals?.map((datas, index) => (
                  <tr className="bg-white  ">
                    <td className="text-center py-4">{index + 1}</td>

                    <td className="text-center py-4">{datas?.name}</td>
                    <td className="text-center py-4">
                      {formatDateTime(datas?.created_at)}
                    </td>
                    <td className="text-center py-4">{datas?.percent_discount}</td>
                    <td className="text-center py-4">
                      {formatDateTime(datas?.start_date)}
                    </td>
                    <td className="text-center py-4">
                      {formatDateTime(datas?.end_date)}
                    </td>
                    {/* <td
                      className={`text-center py-4 
    ${
      datas?.status === "deleted"
        ? "text-red-500"
        : datas?.status === "active"
        ? "text-green-500"
        : "text-yellow-300"
    } font-bold`}
                    >
                      {datas?.status}
                    </td>
                    <td className="text-center py-4 text-blue-500 underline">
                      <NavLink to={`/edit-store/${datas?.id}`}>Edit</NavLink>
                    </td> */}
                    <td className="text-center pl-3 py-4 flex items-center gap-1">
           
          <NavLink to={`/edit-deal/${datas?.id}`} className={'cursor-pointer'}>
            <FaEdit />
          </NavLink>    
                    <button
            type="button"
            onClick={() => toggleDelete(datas?.id)}
            className="outline-none font-xs flex text-red-500 rounded-full text-xs px-2 py-2 text-center"
            >
               <FaTrash />
  </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* </div> */}
            </table>
          </div>
      
        </div>
      </div>


      <section>
        <Modal
          visible={toggleDeleteModal}
          width="350"
          height="430"
          effect="fadeInUp"
          onClickAway={() => setToggleDeleteModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container flex flex-row justify-around bg-[#fff]  items-center rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-end px-2 pt-3">
                  <p className="cursor-pointer font-bold" onClick={(e) => setToggleDeleteModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <label
                  className="flex justify-start mb-2 pt-1 text-md font-bold text-black"
                >
                  Delete {singleDeals?.deal?.deal?.name}
                </label>

                <label
                  style={{ fontSize: '14px' }}
                  className="flex justify-start mb-2 pt-2 text-xs font-medium text-gray-600"
                >
                  You are about to delete the Deal you created.


                </label>
            
             

                <form onSubmit={deleteDeals} className="pb-4 rounded-lg">
                  <span className="flex justify-center pt-4">
                    <button
                      type="submit"
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Delete Deal
                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <button
                      type="button"
                      onClick={(e) => setToggleDeleteModal(false)}
                      style={{ borderRadius: '50px' }}
                      className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                    >
                      Cancel
                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <Oval
                      height={40}
                      width={40}
                      color="#0071BC"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={loader}
                      ariaLabel='oval-loading'
                      secondaryColor="#96cff6"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </span>

                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>
      {/* CREATE MARKET LINK */}







      {/* DELETE MARKET LINK */}



      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}
