import React, { useCallback, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Oval } from "react-loader-spinner";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { PromotionApis } from "../../../apis/promotionApis";

const CardEditPromotion = () => {
  const params = useParams();
  // console.log(params.id)
  const navigate = useNavigate();

  // const [promotionName, setPromotionName] = useState('');
  const [promotionImage, setPromotionImage] = useState<
    { action: string; image?: File | null; url?: string; position?: number }[]
  >([]);
  const [loader, setLoader] = React.useState(false);
  const [productId, setProductId] = useState("");

  const [userData, setUserdata] = useState({
    promotion_name: "",
  });

  // console.log(promotionName)

  React.useEffect(() => {
    PromotionApis.getSinglePromotion(params.id).then((response: any) => {
      if (response?.data) {
        // console.log(response?.data?.promotion_image)
        setUserdata(response?.data?.promotion_image);
        setProductId(response?.data?.promotion_image?.id);

        // Populate promotionImage with existing images from response
        setPromotionImage(
          response?.data?.promotion_image?.promotion_images.map(
            (img: any, index: number) => ({
              action: "keep",
              url: img,
              position: index,
            })
          )
        );
      }
    });
  }, [params.id]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  const uploadImg = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const size = file.size / 1048576.0;
    if (size > 2) {
      toast.warn("Image too large");
      e.target.value = "";
      return;
    }

    setPromotionImage((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = {
        action: newImages[index].url ? "replace" : "add",
        image: file,
        position: index,
        url: undefined,
      };
      return newImages;
    });
  };

  const handleChangeEditImage = (
    index: number,
    field: string,
    value: string
  ) => {
    const newCourses: any = [...promotionImage];
    newCourses[index][field] = value;
    setPromotionImage(newCourses);
  };

  const handleRemoveAddPromotionImage = (index: number) => {
    setPromotionImage((prevImages) => {
      const updatedImages = [...prevImages];
      const targetImage = updatedImages[index];

      if (targetImage.url) {
        updatedImages[index] = {
          action: "remove",
          // url: targetImage.url,
          // position: index,
        };
      } else {
        updatedImages.splice(index, 1);
      }
      return updatedImages;
    });
  };

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const addNewPromotionImage = () => {
    setPromotionImage((prevImages) => [
      ...prevImages,
      { action: "add", image: null, position: prevImages.length },
    ]);
  };

  const fetchImageAsFile = async (url: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], url.split("/").pop() || "image.jpg", {
      type: blob.type,
    });
  };

  const updatePromotion = useCallback(
    async (e: React.FormEvent) => {
        e.preventDefault();
        setLoader(true);

        // Prepare the data to match backend requirements
        const formattedImages = promotionImage.map((img) => {
            if ((img.action === "add" || img.action === "replace") && img.image) {
                return { action: img.action, position: img.position, image: img.image };
            } else if (img.action === "remove" && img.url) {
                return { action: "remove", url: img.url };
            }
            return null;
        }).filter(Boolean); // Filter out null entries

        const data = {
            promotion_name: userData.promotion_name,
            images: formattedImages,
        };

        try {
            const response = await PromotionApis.updatePromotion(productId, data);
            if (response?.data) {
                toast.success(response?.statusText || "Promotion updated successfully");
                navigate("/admin/promotions");
            }
        } catch (error: any) {
            toast.error(error.response?.data?.message || "An error occurred");
        } finally {
            setLoader(false);
        }
    },
    [userData, promotionImage, productId, navigate]
);

  
  

  return (
    <form onSubmit={updatePromotion} className="pb-32 sm:px-3">
      <div className="flex items-center mt-4 justify-between">
        {/* Back button */}
        <button
          type="button"
          onClick={handleBackClick}
          className="flex items-center text-gray-600 mb-4"
        >
          <FaArrowLeft className="mr-2" />
          <span>Back</span>
        </button>
        <div className="md:flex md:justify-end">
          {/* <div className="bg-blue-100 md:w-2/5 rounded-lg m-1 p-2">
                <span className=" bg-blue-100  rounded-lg  text-gray-500 text-[12px]"><span className=" text-red-500 bg-red-200 p-1 px-3 rounded-full text-[15px]">!</span><br />  ATTENTION : Please kindly note that the market link is the link where your customers will see the list of your products</span>
              </div> */}

          <div className="flex justify-end gap-2 mt-5">
            <button
              type="submit"
              className=" text-white h-10 bg-[#0071BC] hover:bg-[#103f5e] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm  px-5 py-2.5 text-center "
            >
              Update Promotion
            </button>

            <Oval
              height={40}
              width={40}
              color="#0071BC"
              wrapperStyle={{}}
              wrapperClass=""
              visible={loader}
              ariaLabel="oval-loading"
              secondaryColor="#96cff6"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        </div>
      </div>

      <div
        className={
          "lg:grid lg:grid-cols-2 gap-2 mt-10 " +
          (loader ? "shadow animate-pulse " : "")
        }
      >
        <div className="flex flex-col gap-6 mb-10">
          <div className=" lg:w-4/5">
            <label
              htmlFor="promotion_name"
              className="block mb-2 mt-6 text-sm  text-gray-900 dark:text-gray-600"
            >
              Promotion Name
            </label>

            <input
              type="text"
              value={userData?.promotion_name || ""}
              onChange={handleChange}
              name="promotion_name"
              className="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              placeholder=""
            />
          </div>

          {/* second Div */}
          <div className="grid grid-cols-2 gap-6">
            {promotionImage.map((image, index) => (
              <div key={index} className="relative">
                <label className="flex flex-col border items-center justify-center w-full rounded-[5px] cursor-pointer">
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={
                        image.image
                          ? URL.createObjectURL(image.image)
                          : image.url || "/images/img1.png"
                      }
                      style={{ minHeight: "200px", maxHeight: "200px" }}
                    />
                  </div>
                  <input
                    onChange={(e) => uploadImg(e, index)}
                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                    type="file"
                    className="hidden mb-2 text-sm text-[#6C757D] font-medium"
                  />
                </label>
                {promotionImage.length > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveAddPromotionImage(index)}
                    className="text-red-500 mt-1"
                  >
                    Remove Promotion Image
                  </button>
                )}
              </div>
            ))}
          </div>
          <button
            type="button"
            onClick={addNewPromotionImage}
            className="text-white h-10 bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5"
          >
            Add Promotion Image
          </button>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </form>
  );
};

export default CardEditPromotion;
