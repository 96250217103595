import { AxiosPromise } from "axios";
import configs from "../configs";
import { ProductsLiveApi } from "./live/productsLiveApis";
import { CartLiveApis } from "./live/cartLiveApis";
import { StoreLiveApis } from "./live/storeLiveApis";



export class StoreApis {
    private static productApis: StoreLiveApis = new StoreLiveApis();

    static createStore(data:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.createStore(data);
        }
    } 

    // static createDeals(data:any): AxiosPromise<any> {
    //     if (configs.type === "LOCAL") {
    //         return {} as AxiosPromise;
    //     } else {
    //         return this.productApis.createDeals(data);
    //     }
    // } 

    // static getDeals(): AxiosPromise<any> {
    //     if (configs.type === "LOCAL") {
    //         return {} as AxiosPromise;
    //     } else {
    //         return this.productApis.getDeals();
    //     }
    // } 
    
    static getStore(): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.getStore();
        }
    } 

     
    static getSingleStore(query:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.getSingleStore(query);
        }
    } 

    

    static updateStore(id:any, data:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.updateStore(id, data);
        }
    } 

    static deleteStore(id:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.deleteStore(id);
        }
    } 


}