import React from "react";

const CategoryLoader = () => {
  return (
    <div className="md:px-8 px-4 w-full animate-pulse pt-[20px] md:pt-[20px] ">
      <section className=" p-3">
        <div className=" mx-auto grid md:gap-16 gap-8 grid-cols-3 md:grid-cols-4 lg:grid-cols-7 lg:space-y-0">
        <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>
          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

          <div>
            <div className="border  flex justify-center py-2 max-h-32 max-w-full rounded-lg border-gray-300">
              <div className="h-[100px] bg-gray-300 dark:bg-gray-400 border border-gray-300 rounded-lg  mx-2 w-full"></div>
            </div>
            <div className="flex justify-center text-center">
              <div className="h-2.5   mt-3 bg-gray-300 rounded-full dark:bg-gray-400 w-20"></div>
            </div>
          </div>

        

          
        </div>
      </section>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default CategoryLoader;
