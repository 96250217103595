import React from "react";
import AdminNavbar from "../Navbars/AdminNavbar";
import CardCreateCategory from "../Cards/CardCreateCategory";
import Sidebar from "../Sidebar/Sidebar";
import CreateCardStore from "../Cards/CreateCardStore";

const CreateStore = () => {
  return (
    <>
      <Sidebar title="Create Store" />
      <div className="relative md:ml-64 bg-white">
        <AdminNavbar title="Create Store" />
        <div className="flex flex-wrap md:mt-4 mt-1">
          <div className="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
            <CreateCardStore />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateStore;
