import { AxiosPromise } from "axios";
import configs from "../configs";
import { PromotionLiveApis } from "./live/promotionLiveApis";



export class PromotionApis {
    private static PromotionApi: PromotionLiveApis = new PromotionLiveApis();   

    static createPromotion(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.PromotionApi.createPromotion(data);
        }
    }
    static getAllPromotions(): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.PromotionApi.getAllPromotions();
        }
    } 
    static updatePromotion(id:any, data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.PromotionApi.updatePromotion(id, data);
        }
    }  

    static updatePromotionStatus(id:any, status:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.PromotionApi.updatePromotionStatus(id, status);
        }
    }  

 
    static getSinglePromotion(id: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.PromotionApi.getSinglePromotion(id);
        }
    }


    static deletePromotion(id: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.PromotionApi.deletePromotion(id);
        }
    }

}