import React from 'react'
import { useParams } from 'react-router-dom';

const OrderCardUserPurchaseList = () => {
  const params = useParams();

  return (
    <div>OrderCardUserPurchaseList</div>
  )
}

export default OrderCardUserPurchaseList