import React, { useEffect } from 'react'
import {
    Link,
    NavLink,
    useNavigate,
    useParams,
    useSearchParams,
  } from "react-router-dom";
import { AxiosResponse } from "axios";

import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { IoArrowBack } from "react-icons/io5";
import { AiOutlineSetting } from "react-icons/ai";
import { CategoryApis } from "../apis/CategoryApis";
import { CartApis } from '../apis/cartApis';
import { ProductApis } from '../apis/productApis';
import { login } from '../reducer/loginSlice';
import { AuthApis } from '../apis/authApis';
import Footer from './Footers/Footer';
import Navbar from './Navbars/Navbar';



const TermsAndCondition = () => {
    const userLoginData = useSelector((state: any) => state.data.login.value);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const dispatch: Dispatch = useDispatch();

  const [loader, setLoader] = React.useState<boolean>(false);
    
  const [products, setProducts] = React.useState<any>([]);
  const [loc, setLoc] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [fetchCategory, setFetchCategory] = React.useState("");

  const [collapseShow, setCollapseShow] = React.useState("hidden");

  let [target, setTarget] = React.useState<Element>();

  const params = useParams();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setname] = React.useState("");
  const [total2, setTotal2] = React.useState<any>("");
  const [total, setTotal] = React.useState<any>("");

  React.useEffect(() => {
    CartApis.getCartCount().then((response: AxiosResponse<any>) => {
      if (response?.data) {
        setname(response?.data?.cart);
        setTotal2(response?.data?.total);
      } else {
        dispatch(login([]));
      }
    });

    CategoryApis.getCategory("")
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setTotal(response?.data?.data);
        } else {
          dispatch(login([]));
        }
      })
      .catch(function (error: any) {
        // handle error
        console.log(error.response.data);
      });
  }, []);

  if (searchParams.get("status") == "cancelled") {
    navigate(`/store/${params?.storeId}`);
  }

  React.useEffect(() => {
    setLoader(true);
    const query: any = {
      search: search,
      name: loc,
      categories: category,
    };
    ProductApis.getAllProducts("", query)
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          setProducts(response.data);
          setLoader(false);

          // console.log(response?.data?.data);
        }
      })
      .catch(function (error: any) {
        // handle error
        console.log(error.response.data);
      });
  }, [category]);

  const logOut = () => {
    dispatch(login([]));
    AuthApis.logout("")
      .then((response: AxiosResponse<any>) => {
        if (response?.data) {
          navigate("/sign-in");
        }
      })
      .catch(function (error: any) {
        // handle error
        console.log(error.response.data);
        console.log("new error");
      });
  };

  const paginator = React.useCallback(
    (value: any) => {
      setLoader(true);
      let value2 = "";
      if (value !== null) {
        value2 = value;
      } else {
        value2 = "";
      }
      const query: any = {
        search: search,
        name: loc,
        categories: category,
      };
      ProductApis.getAllProducts(value2, query)
        .then((response: AxiosResponse<any>) => {
          if (response?.data) {
            setProducts(response.data);
            setLoader(false);
            setFetchCategory(search !== "" ? search : category);
          }
        })
        .catch(function (error: any) {
          // handle error
          console.log(error.response.data);
          console.log("new error");
        });
    },
    [search, loc, category, fetchCategory]
  );

  const [dea, setDea] = React.useState<any>([]);
  const [searc, setSearc] = React.useState<any>("");
  const [loade, setLoade] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoader(true);
    const query = {
      search: searc,
      name: "",
      categories: "",
    };
    ProductApis.getAllDeals("", query)
      .then((response) => {
        if (response?.data) {
          setDea(response.data);
          setLoade(false);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error.response.data);
      });
  }, []);
  return (
    <div>
   <Navbar />


      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-6">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
          <h1 className="text-3xl font-bold mb-6 text-center">Terms and Conditions for Mmartplus </h1>
          <div className="space-y-4 text-gray-700">
            <p className="text-2xl text-center">
            E-commerce Store

            </p>
            <p className='text-center'>Effective Date: June 20 2024</p>

            <h2 className="text-2xl font-semibold">1. Introduction</h2>
            <p>
            Welcome to Mmartplus, an online grocery store operated by Mmartplus Inc. By accessing or using our website, you agree to comply with and be bound by these Terms and Conditions. Please read them carefully.
            </p>
            <h2 className="text-2xl font-semibold">
              2. Acceptance of Terms
            </h2>
            <p>
            By using our services, you agree to these terms and any modifications. If you do not agree, please refrain from using our website.
            </p>



            <h2 className="text-2xl font-semibold">
              3. Eligibility
            </h2>
            <p>
            You must be at least 14 years old to use our services. By using our site, you confirm that you meet this age requirement.
            </p>

            <h2 className="text-2xl font-semibold">4. Account Registration</h2>
            <div className='pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li>Account Creation: To make a purchase, you must create an account. Ensure your information is accurate and up-to-date.
                                        </li>
                                        <li>Confidentiality: You are responsible for maintaining the confidentiality of your account details and password. Notify us immediately of any unauthorized use.
                                        </li>
                                  
                                    </ul>
                                </div>

            <h2 className="text-2xl font-semibold">5. Products and Services</h2>
            <div className='pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li>Product Availability: All products are subject to availability and may be withdrawn at any time.
                                        </li>
                                        <li>Pricing: Prices are subject to change without notice. We strive for accuracy but are not liable for errors.
                                        </li>
                                  
                                    </ul>
                                </div>

            <h2 className="text-2xl font-semibold">
              6.  Orders and Payment
            </h2>
            <div className='pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li>Order Acceptance: Your order constitutes an offer to buy. We will confirm acceptance by sending a confirmation email.
                                        </li>
                                        <li>Payment: Payment must be made through the methods provided on our site. All payments must be received before products are dispatched.
                                        </li>
                                  
                                    </ul>
                                </div>

            <h2 className="text-2xl font-semibold">7. Delivery</h2>
            <div className='pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li>Shipping Policy: Delivery times and costs are detailed on our website. We are not liable for delays beyond our control.
                                        </li>
                                        <li>Risk of Loss: The risk of loss passes to you upon delivery.
                                        </li>
                                  
                                    </ul>
                                </div>

            <h2 className="text-2xl font-semibold">8. Returns and Refunds</h2>
            <div className='pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li>Refer to our refund policy page for more details</li>
                                  
                                    </ul>
                                </div>

                                <h2 className="text-2xl font-semibold">9. User Conduct</h2>
           <p>You agree not to use our website for any unlawful purpose or in any way that could harm Mmartplus or its users. Prohibited activities include, but are not limited to:</p>
            <div className=' pl-4'>
                                    <ul className='list-disc list-outside space-y-2'>
                                        <li>Hacking or attempting to gain unauthorized access to the website or other systems.
                                        </li>
                                        <li>Misrepresenting your identity or providing false information.
                                        </li>
                                  
                                    </ul>
                                </div>

                                <h2 className="text-2xl font-semibold">10. Intellectual Property</h2>
           <p>All content on the Mmartplus website, including text, graphics, logos, images, and software, is the property of Mmartplus or its licensors and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works without our permission.</p>
           

           <h2 className="text-2xl font-semibold">11. Limitation of Liability</h2>
           <p>Mmartplus is not liable for any direct, indirect, incidental, or consequential damages arising from your use of our website or inability to use the site.</p>
           
           <h2 className="text-2xl font-semibold">12. Indenification</h2>
           <p>You agree to indemnify and hold Mmartplus harmless from any claims, damages, losses, liabilities, and expenses arising out of your use of our website or violation of these terms.</p>
           
           <h2 className="text-2xl font-semibold">13. Modifications to Terms</h2>
           <p>We reserve the right to modify these Terms and Conditions at any time. Changes will be posted on our website, and your continued use constitutes acceptance of the revised terms.</p>
           
           <h2 className="text-2xl font-semibold">14. Governing Law</h2>
           <p>These Terms and Conditions are governed by the laws of Nigeria. Any disputes will be resolved in the courts of Nigeria.</p>
           
           <h2 className="text-2xl font-semibold">15. Contact Information</h2>
           <p>For any questions or concerns, please contact us at: <a href='mailto:info@mmartplus.com'>info@mmartplus.com</a></p>
           
          </div>
        </div>
      </div>

      <div className="-mt-10">
        <Footer />
      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default TermsAndCondition
