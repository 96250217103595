import { AxiosPromise } from "axios";
import configs from "../configs";
import { ProductsLiveApi } from "./live/productsLiveApis";
import { CartLiveApis } from "./live/cartLiveApis";
import { CategoryLiveApis } from "./live/CategoryLiveApis";



export class CategoryApis {
    private static productApis: CategoryLiveApis = new CategoryLiveApis();

    static createCategory(data:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.createCategory(data);
        }
    } 

    static createDeals(data:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.createDeals(data);
        }
    } 

    static getDeals(): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.getDeals();
        }
    } 

    static getSingleDeals(query:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.getSingleDeals(query);
        }
    }

    static deleteDeals(id: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.deleteDeals(id);
        }
    }
    
    static getCategory(query:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.getCategory(query);
        }
    } 

     
    static getSingleCategory(query:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.getSingleCategory(query);
        }
    } 

    

    static updateCategory(data:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.updateCategory(data);
        }
    } 

    static deleteCategory(id:any): AxiosPromise<any> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.productApis.deleteCategory(id);
        }
    } 


}