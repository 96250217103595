import React, { useState, useEffect, useCallback } from "react";
import { CategoryApis } from "../../apis/CategoryApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { ProductApis } from "../../apis/productApis";
import { FaArrowLeft } from "react-icons/fa";

interface Product {
  id: number;
  product_name: string;
  // Add other fields here as needed
}

const CardEditDeal = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [total, setTotal] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [addCategory, setAddCategory] = useState([{ category: "" }]);
  const [products, setProducts] = useState<Product[]>([]); // Explicitly set type for products
  const [dealProducts, setDealProducts] = useState([]); // State to hold deal products data

  const [userData, setUserData] = useState<any>({
    name: "",
    percent_discount: "",
    start_date: "",
    end_date: "",
    id: "",
    product_ids: [],
  });
  // console.log(userData)
  // Initialize `productIds` with `dealProducts` on initial load
  useEffect(() => {
    CategoryApis.getSingleDeals(params?.id).then((response) => {
      if (response?.data) {
        const fetchedDealProducts = response.data.deal.products.map(
          (product: any) => product.id
        ); 
        console.log(response.data)
        setDealProducts(fetchedDealProducts);
        setProductIds(fetchedDealProducts);
        const dealData = response?.data?.deal?.deal;
        setUserData({
          ...dealData,
          product_ids: dealData.product_ids || [],
        });
      }
    });
  }, [params]);

  useEffect(() => {
    CategoryApis.getCategory("").then((response) => {
      if (response?.data) {
        setTotal(response?.data?.data);
      }
    });
  }, []);

  const [page, setPage] = useState(1); // Current page for pagination
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [productIds, setProductIds] = useState<number[]>([]);

  // Fetch products with pagination
  // Fetch products with pagination
  const fetchProducts = (page: number) => {
    setLoading(true);
    ProductApis.getAllProducts(page, { search: "", name: "", categories: "" })
      .then((response) => {
        if (response?.data?.data) {
          const newProducts = response.data.data.data;
          setProducts((prev: Product[]) => {
            // Merge only unique products based on the `id`
            const mergedProducts = [...prev, ...newProducts].reduce(
              (acc: Product[], product) => {
                if (!acc.find((p) => p.id === product.id)) {
                  acc.push(product);
                }
                return acc;
              },
              []
            );
            return mergedProducts;
          });
          setHasMore(!!response.data.data.next_page_url);
        } else {
          console.error("Unexpected data format:", response?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      })
      .finally(() => setLoading(false));
  };

  // Load initial products and on page change
  useEffect(() => {
    fetchProducts(page);
  }, [page]);

  // Load initial products and on page change
  useEffect(() => {
    fetchProducts(page);
  }, [page]);

  // Handle product selection
  // Toggle selection for both existing and new products
  const toggleProductSelection = (productId: any) => {
    setProductIds((prevIds) =>
      prevIds.includes(productId)
        ? prevIds.filter((id) => id !== productId)
        : [...prevIds, productId]
    );
  };

  const handleAddCategory = () => {
    setAddCategory([...addCategory, { category: "" }]);
    setUserData({ ...userData, product_ids: [...userData.product_ids, ""] });
  };

  const handleRemoveAddCategory = (index: any) => {
    const newCategories = addCategory.filter((_, i) => i !== index);
    setAddCategory(newCategories);

    const updatedProductIds = userData.product_ids.filter(
      (_: any, i: any) => i !== index
    );
    setUserData({ ...userData, product_ids: updatedProductIds });
  };

  const handleCategoryChange = (index: any, value: any) => {
    const newCategories = [...addCategory];
    newCategories[index].category = value;
    setAddCategory(newCategories);

    const updatedProductIds: any = [...userData.product_ids];
    updatedProductIds[index] = value;
    setUserData({ ...userData, product_ids: updatedProductIds });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const createProduct = useCallback(
    (e: any) => {
      e.preventDefault();
      // setLoader(true);
      const data = {
        name: userData.name,
        percent_discount: userData.percent_discount,
        start_date: userData.start_date,
        end_date: userData.end_date,
        id: userData.id,
        product_ids: productIds,
      };
      // console.log(data)
      CategoryApis.createDeals(data)
        .then((response: any) => {
          if (response?.data) {
            toast.success(response?.data?.message);
            navigate("/admin/deals");
          } else {
            toast.error(response?.response?.data?.message);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => setLoader(false));
    },
    [userData, productIds, navigate]
  );
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <div>
      {/* Back button */}
      <button
        type="button"
        onClick={handleBackClick}
        className="flex items-center text-gray-600 mb-4"
      >
        <FaArrowLeft className="mr-2" />
        <span>Back</span>
      </button>
      <form onSubmit={createProduct} className="pb-32 sm:px-3">
        <div className="grid md:grid-cols-2 gap-2">
          <div>
            <label
              htmlFor="name"
              className="block mb-2 mt-6 text-sm text-gray-900"
            >
              Deal Name
            </label>
            <input
              type="text"
              defaultValue={userData?.name || ""}
              onChange={handleChange}
              id="name"
              name="name"
              className="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              placeholder="Deals Name"
            />
          </div>

          <div>
            <label
              htmlFor="percent_discount"
              className="block mb-2 mt-6 text-sm text-gray-900"
            >
              Percent discount
            </label>
            <input
              required
              type="number"
              defaultValue={userData?.percent_discount}
              onChange={handleChange}
              id="percent_discount"
              name="percent_discount"
              className="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
              placeholder="Eg.10,20..."
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-2">
          <div>
            <label
              htmlFor="start_date"
              className="block mb-2 mt-6 text-sm text-gray-900"
            >
              Start Date
            </label>
            <input
              type="date"
              defaultValue={userData?.start_date}
              onChange={handleChange}
              id="start_date"
              name="start_date"
              className="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            />
          </div>
          <div>
            <label
              htmlFor="end_date"
              className="block mb-2 mt-6 text-sm text-gray-900"
            >
              End Date
            </label>
            <input
              type="date"
              defaultValue={userData?.end_date}
              onChange={handleChange}
              id="end_date"
              name="end_date"
              className="bg-[#F4FBFF] border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            />
          </div>
        </div>

        {/* Product Selection with Pagination */}
        <div>
          <label className="block mb-2 mt-6 text-sm text-gray-900">
            Select Products
          </label>
          <div className="border rounded-lg p-3 max-h-60 overflow-y-auto bg-[#F4FBFF]">
            <div className="grid grid-cols-2 gap-1">
              {products.map((product: Product) => (
                <div key={product.id} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    checked={productIds.includes(product.id)}
                    onChange={() => toggleProductSelection(product.id)}
                    className="mr-2"
                  />
                  <span className="text-sm">{product.product_name}</span>
                </div>
              ))}
            </div>
            {loading && <p>Loading...</p>}
          </div>
          {hasMore && (
            <button
              type="button"
              onClick={() => setPage((prevPage) => prevPage + 1)}
              className="mt-2 text-blue-500"
            >
              Load More
            </button>
          )}
        </div>

        <div className="flex justify-center gap-2 mt-5">
          <button
            type="submit"
            className="text-white h-10 bg-[#0071BC] hover:bg-[#103f5e] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-10 mt-10 py-2.5 text-center"
          >
            Update
          </button>

          {loader && (
            <Oval
              height={40}
              width={40}
              color="#0071BC"
              ariaLabel="oval-loading"
              secondaryColor="#96cff6"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          )}
        </div>
      </form>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        pauseOnHover
      />
    </div>
  );
};

export default CardEditDeal;
