import React from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//@ts-ignore
import Modal from "react-awesome-modal";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { AdminApis } from "../../apis/adminApi";
import { login } from "../../reducer/loginSlice";
import Sidebar from "../Sidebar/Sidebar";
import { CategoryApis } from "../../apis/CategoryApis";
import { formatDateTime } from "../Reusable/functions";
import { StoreApis } from "../../apis/storeApis";

const CardStore = () => {
  const userLoginData = useSelector((state) => state.data.login.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stores, setStores] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    if (!userLoginData?.id) {
      navigate("/sign-in");
    }
  });

  React.useEffect(() => {
    StoreApis.getStore()
      .then((response) => {
        if (response?.data) {
          // console?.log(response?.data);
          setStores(response?.data);
        } else {
          // dispatch(login([]))
        }
      })
      .catch(function (error) {});
  }, []);

  // const updateSinglePurchase = React.useCallback(
  //   (e) => {

  //     e.preventDefault();
  //     let data = {
  //       'status': orderStatus,
  //       'id': purchaseData?.id
  //     }

  //     AdminApis.updateSinglePurchase(data).then(
  //       (response) => {
  //         if (response?.data) {
  //           setVisible2(false)
  //           toast.success(response?.data?.message);
  //           const query = {
  //             search: searchText,
  //           };
  //           AdminApis.getAllPurchase('', query).then(
  //             (response) => {
  //               if (response?.data) {
  //                 setStores(response?.data?.data)
  //                 console?.log(response?.data?.data)
  //               } else {
  //                 dispatch(login([]))
  //               }
  //             }
  //           )
  //         } else {
  //           toast.error(response?.response?.data?.message);
  //         }
  //       }
  //     ).catch(function (error) {
  //       toast.error(error.response.data.message);
  //     })
  //   }
  //   ,
  //   [purchaseData, orderStatus, visible2, setores]
  // );

  // const paginator = React.useCallback(
  //   (value) => {

  //     let value2 = '';
  //     if (value !== null) {
  //       value2 = value;
  //     } else {
  //       value2 = ''
  //     }
  //     const query = {
  //       search: searchText,
  //     };

  //     AdminApis.getAllPurchase(value2, query).then(
  //       (response) => {
  //         if (response?.data) {
  //           setStores(response?.data?.data)
  //         }
  //       }
  //     ).catch(function (error) {
  //       console.log(error.response.data);
  //     })

  //   }, [setores, searchText]);

  // const updatePurchase = React.useCallback(
  //   (e) => {

  //     e.preventDefault();
  //     let data = {
  //       'status': orderStatus,
  //       'order_id': orderId
  //     }

  //     AdminApis.updatePurchases(data).then(
  //       (response) => {
  //         if (response?.data) {
  //           setVisible(false)
  //           toast.success(response?.data?.data?.message);

  //           const query = {
  //             search: searchText,
  //           };
  //           AdminApis.getAllPurchase('', query).then(
  //             (response) => {
  //               if (response?.data) {
  //                 setStores(response?.data?.data)
  //                 console?.log(response?.data?.data)
  //               } else {
  //                 dispatch(login([]))
  //               }
  //             }
  //           )
  //         } else {
  //           toast.error(response?.response?.data?.message);
  //         }
  //       }
  //     ).catch(function (error) {
  //       toast.error(error.response.data.message);
  //     })
  //   }
  //   ,
  //   [orderId, orderStatus, visible, setores]
  // );

  return (
    <div>
      <div className="bg-white">
        <div className="py-4 lg:px-10 px-6 ">
          <h1 className="text-[30px] font-semibold">Stores</h1>
          <div className="flex justify-end">
            <div className=" mr-3 flex justify-end">
              <div className="relative flex w-full flex-wrap items-stretch">
                <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#9da4aa"
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z"
                    />
                  </svg>
                </div>
                {/* <input type="text" onClick={() => ('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required /> */}
              </div>

              {/* <div className='mt-0.5 ml-2'><button type='button' onClick={(e) => paginator('')} className={"font-normal text-white bg-[#0071BC] px-3 py-0.5 rounded-md"}>Search</button> </div> */}
              <div className="mt-0.5 ml-2">
                <NavLink to={"/admin/create-store"}>
                  <button
                    type="button"
                    // onClick={(e) => setVisible(true)}
                    className={
                      "font-normal text-white bg-[#0071BC] px-3 py-0.5 rounded-md"
                    }
                  >
                    Create
                  </button>
                </NavLink>
              </div>
            </div>

            {/* <div className='mt-1'>Filter </div> */}
          </div>

          <div className="shadow-lg sm:rounded-lg w-full mt-6">
            <table className=" text-sm w-full text-gray-500 ">
              {/* <div className='w-full '> */}
              <thead className="text-xs text-gray-700 w-full px-5 bg-gray-50 ">
                <tr className="w-full">
                  <th scope="" className=" py-3">
                    S/N
                  </th>
                  <th scope="" className=" py-3">
                    Store Name
                  </th>
                  <th scope="" className=" py-3">
                    Store Address
                  </th>
                  <th scope="" className=" py-3">
                    Date Created
                  </th>
                  <th scope="" className=" py-3">
                    Status
                  </th>
                  <th scope="" className=" py-3">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody className="">
                {stores.stores?.map((datas, index) => (
                  <tr className="bg-white  ">
                    <td className="text-center py-4">{index + 1}</td>

                    <td className="text-center py-4">{datas?.name}</td>
                    <td className="text-center py-4">{datas?.address}</td>
                    <td className="text-center py-4">
                      {formatDateTime(datas?.created_at)}
                    </td>
                    <td
                      className={`text-center py-4 
    ${
      datas?.status === "deleted"
        ? "text-red-500"
        : datas?.status === "active"
        ? "text-green-500"
        : "text-yellow-300"
    } font-bold`}
                    >
                      {datas?.status}
                    </td>
                    <td className="text-center py-4 text-blue-500 underline">
                      <NavLink to={`/edit-store/${datas?.id}`}>Edit</NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* </div> */}
            </table>
          </div>
          {/* <div className='m-4 flex justify-end'>
            {
              setores?.links?.filter(((item, idx) => idx < 1000)).map(
                (datas, index) => (
                  <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url.charAt(datas?.url.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url.charAt(datas?.url.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + (datas?.active == false ? 'bg-gray-300 text-black ' : 'bg-[#0071BC] text-white')}>
                    {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                  </button>
                )
              )
            }

          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CardStore;
